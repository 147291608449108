import React, { Component } from "react";
import { connect } from "react-redux";
import {
	fetchCompletedLoans,
	fetchMoreCompletedLoans,
	fetchCurrentLoans,
	fetchMoreCurrentLoans,
	fetchProcessingLoans,
	fetchMoreProcessingLoans,
	fetchCollectionsLoans,
	fetchMoreCollectionsLoans,
	fetchDefaultLoans,
	fetchMoreDefaultLoans,
	fetchUserData,
} from "../../redux";
import { NavLink, Link } from "react-router-dom";

// Import Components
import InvestmentCard from "../investmentCard/investmentCard";
import MobileNav from "../mobileNav/mobileNav";
import ShowMore from "../common/showMore/showMore";

// Import Images
import GetStaredImage from "../../img/static/investment-get-started.svg";
import ZeroStateImage from "../../img/static/investment-zero-state.svg";
import InvestmentCardLoadingBlock from "../../img/spinners/blocks/investment cards.svg";

// Import CSS
import "./investmentPage.css";
import { withRouter } from "../../utils/withRouterHOC";
import FlipMove from 'react-flip-move';

class InvestmentPage extends Component {
	state = {
		getStartedPage: false,
	};

	// When Mount
	componentDidMount() {
		this.props.fetchCompletedLoans();
		this.props.fetchCurrentLoans();
		this.props.fetchProcessingLoans();
		this.props.fetchCollectionsLoans();
		this.props.fetchDefaultLoans();
		this.props.fetchUserData();
	}

	// Helper Methods
	handleNavbarNotificationsCount = (type) => {
		const {
			completedLoansData,
			currentLoansData,
			processingLoansData,
			collectionsLoansData,
			defaultLoansData,
		} = this.props;
		let value = 0;

		switch (type) {
			case "ALL":
				if (completedLoansData.loans.count) {
					value += completedLoansData.loans.count;
				}
				if (currentLoansData.loans.count) {
					value += currentLoansData.loans.count;
				}
				if (processingLoansData.loans.count) {
					value += processingLoansData.loans.count;
				}
				if (collectionsLoansData.loans.count) {
					value += collectionsLoansData.loans.count;
				}
				if (defaultLoansData.loans.count) {
					value += defaultLoansData.loans.count;
				}
				break;

			case "CURRENT":
				if (currentLoansData.loans.count) {
					value += currentLoansData.loans.count;
				}
				break;

			case "PROCESSING":
				if (processingLoansData.loans.count) {
					value += processingLoansData.loans.count;
				}
				break;

			case "COMPLETED":
				if (completedLoansData.loans.count) {
					value += completedLoansData.loans.count;
				}
				break;

			case "COLLECTIONS":
				if (collectionsLoansData.loans.count) {
					value += collectionsLoansData.loans.count;
				}
				break;

			case "DEFAULT":
				if (defaultLoansData.loans.count) {
					value += defaultLoansData.loans.count;
				}
				break;

			default:
				break;
		}

		if (value >= 100) return "(99+)";
		if (value === 0) return null;
		return `(${value})`;
	};

	noLoansAreFound = (type) => {
		const {
			completedLoansData,
			currentLoansData,
			processingLoansData,
			collectionsLoansData,
			defaultLoansData,
		} = this.props;

		switch (type) {
			case "ALL":
				if (
					!completedLoansData.loading &&
					!currentLoansData.loading &&
					!processingLoansData.loading &&
					!defaultLoansData.loading
				) {
					if (
						completedLoansData.loans.results?.length === 0 &&
						currentLoansData.loans.results?.length === 0 &&
						processingLoansData.loans.results?.length === 0 &&
						collectionsLoansData.loans.results?.length === 0 &&
						defaultLoansData.loans.results?.length === 0
					) {
						return true;
					}
				}
				return false;

			case "CURRENT":
				if (!currentLoansData.loading) {
					if (currentLoansData.loans.results?.length === 0) {
						return true;
					}
				}
				return false;

			case "PROCESSING":
				if (!processingLoansData.loading) {
					if (processingLoansData.loans.results?.length === 0) {
						return true;
					}
				}
				return false;

			case "COMPLETED":
				if (!completedLoansData.loading) {
					if (completedLoansData.loans.results.length === 0) {
						return true;
					}
				}
				return false;

			case "COLLECTIONS":
				if (!collectionsLoansData.loading) {
					if (collectionsLoansData.loans.results?.length === 0) {
						return true;
					}
				}
				return false;

			case "DEFAULT":
				if (!defaultLoansData.loading) {
					if (defaultLoansData.loans.results?.length === 0) {
						return true;
					}
				}
				return false;

			default:
				return true;
		}
	};

	isLoading = (type) => {
		const {
			completedLoansData,
			currentLoansData,
			processingLoansData,
			collectionsLoansData,
			defaultLoansData,
		} = this.props;

		switch (type) {
			case "CURRENT":
				if (currentLoansData.loading) return true;
				return false;

			case "PROCESSING":
				if (processingLoansData.loading) return true;
				return false;

			case "COMPLETED":
				if (completedLoansData.loading) return true;
				return false;

			case "COLLECTIONS":
				if (collectionsLoansData.loading) return true;
				return false;

			case "DEFAULT":
				if (defaultLoansData.loading) return true;
				return false;

			default:
				return false;
		}
	};

	loadingBlock = () => {
		const {
			completedLoansData,
			currentLoansData,
			processingLoansData,
			collectionsLoansData,
			defaultLoansData,
			userData,
		} = this.props;
		return (

			<>
				<div className="title">Processing loans</div>
				<div className="body">
					<div>
						{processingLoansData?.loans?.results?.slice(0, 2).map((item) => (
							<div key={item.id} className="card investment-card mt-4" style={{width: '100%', height: '176px'}}>
							</div>
						))}
					</div>
				</div>
			</>
		  )
	};

	render() {
		const {
			completedLoansData,
			currentLoansData,
			processingLoansData,
			collectionsLoansData,
			defaultLoansData,
			userData,
		} = this.props;
		const { loansType } = this.props.params;

		// Get Started
		if (this.state.getStartedPage) {
			return (
				<div>
					<div className="dashboard-wrapper">
						<div className="dashboard-content">
							<div className="get-started-page">
								<div className="title">
									Welcome {userData.user.first_name} {userData.user.last_name}!
								</div>
								<p className="message">
									Find your investment opportunities on the Homepage. You can send as many offers as
									you want.
								</p>
								<img src={GetStaredImage} alt="Not Found" className="image" />
								<Link to="/user/dashboard" className="btn btn-black btn-lg btn-block">
									Get started
								</Link>
							</div>
						</div>
					</div>
				</div>
			);
		}

		// Render
		return (
			<div className="investment-page">
				{/* Content */}
				<div className="dashboard-wrapper">
					{/* Mobile Nav */}
					<MobileNav
						title="My loan"
						items={[
							{ id: 0, name: "All", path: "/user/loans" },
							{ id: 1, name: "Processing", path: "/user/loans/processing" },
							{ id: 2, name: "Current", path: "/user/loans/current" },
							{ id: 3, name: "Completed", path: "/user/loans/completed" },
							{ id: 3, name: "Collections", path: "/user/loans/collections" },
							{ id: 3, name: "Defaults", path: "/user/loans/defaults" },
						]}
					/>

					<div className="dashboard-content">
						{/* Nav */}
						<div className="d-none d-sm-block">
							<div className="page-title">My Loan</div>
							<div className="my-4">
								<div className="params-nav">
									<NavLink to="/user/loans" end >
										All {this.handleNavbarNotificationsCount("ALL")}
									</NavLink>
									<NavLink to="/user/loans/processing">
										Processing {this.handleNavbarNotificationsCount("PROCESSING")}
									</NavLink>
									<NavLink to="/user/loans/current">
										Current {this.handleNavbarNotificationsCount("CURRENT")}
									</NavLink>
									<NavLink to="/user/loans/completed">
										Completed {this.handleNavbarNotificationsCount("COMPLETED")}
									</NavLink>
									<NavLink to="/user/loans/collections">
										Collections {this.handleNavbarNotificationsCount("COLLECTIONS")}
									</NavLink>
									<NavLink to="/user/loans/defaults">
										Defaults {this.handleNavbarNotificationsCount("DEFAULT")}
									</NavLink>
								</div>
							</div>
						</div>

						{/* Body */}
						<div>
							{/* All Tabs Zero State */}
							{loansType === undefined && this.noLoansAreFound("ALL") && (
								<div className="zero-state-section">
									<div className="zero-state-title">You have no loans.</div>

									<div className="d-flex justify-content-center justify-content-md-start">
										<p>
											<div>
												Find your investment opportunities on the Homepage. You can send as many
												offers as you want.
											</div>
										</p>
									</div>

									<div className="image-container">
										<img src={ZeroStateImage} alt="Not Found" />
									</div>

									<div className="d-flex justify-content-center justify-content-md-start">
										<Link to="/user/dashboard" className="btn btn-lg btn-black btn-block my-3">
											Get started
										</Link>
									</div>
								</div>
							)}

							{/* All Tabs */}
							{loansType === undefined && !this.noLoansAreFound("ALL") && (
								<>
									<div className="loans-container">
										{/* Loading */}
										{this.isLoading("PROCESSING") && (
											<div className="my-3">{this.loadingBlock()}</div>
										)}

										{/* Zero State */}
										{this.noLoansAreFound("PROCESSING") && (
											<div className="zero-state-title">You have no processing loans</div>
										)}

										{/* Loans */}
										{!this.isLoading("PROCESSING") && !this.noLoansAreFound("PROCESSING") && (
											<>
												<div className="title">Processing loans</div>
												<div className="body">
													{/* <div> */}
													<FlipMove
														staggerDurationBy="20"
														duration={500}
														enterAnimation="elevator"
														leaveAnimation="elevator"
														typeName="div"
														style={{margin: 0, padding: 0}}
													>
														{processingLoansData.loans.results.slice(0, 2).map((item) => (
															<InvestmentCard
																key={item.id}
																data={item}
																loansType="processing"
															/>
														))}
													</FlipMove>
													{/* </div> */}
													<div className="see-more-loans-link">
														<Link to="/user/loans/processing">
															See more processing loans
														</Link>
													</div>
												</div>
											</>
										)}
									</div>

									<div className="loans-container">
										{/* Loading */}
										{this.isLoading("CURRENT") && <div className="my-3">{this.loadingBlock()}</div>}

										{/* Zero State */}
										{this.noLoansAreFound("CURRENT") && (
											<div className="zero-state-title">You have no current loans</div>
										)}

										{/* Loans */}
										{!this.isLoading("CURRENT") && !this.noLoansAreFound("CURRENT") && (
											<>
												<div className="title">Current loans</div>
												<div className="body">
													<div>
														{currentLoansData.loans.results.slice(0, 2).map((item) => (
															<InvestmentCard
																key={item.id}
																data={item}
																loansType="current"
															/>
														))}
													</div>
													<div className="see-more-loans-link">
														<Link to="/user/loans/current">
															See more current loans
														</Link>
													</div>
												</div>
											</>
										)}
									</div>

									<div className="loans-container">
										{/* Loading */}
										{this.isLoading("COMPLETED") && (
											<div className="my-3">{this.loadingBlock()}</div>
										)}

										{/* Zero State */}
										{this.noLoansAreFound("COMPLETED") && (
											<div className="zero-state-title">You have no completed loans</div>
										)}

										{/* Loans */}
										{!this.isLoading("COMPLETED") && !this.noLoansAreFound("COMPLETED") && (
											<>
												<div className="title">Completed loans</div>
												<div className="body">
													<div>
														{completedLoansData.loans.results.slice(0, 2).map((item) => (
															<InvestmentCard
																key={item.id}
																data={item}
																loansType="completed"
															/>
														))}
													</div>
													<div className="see-more-loans-link">
														<Link to="/user/loans/completed">
															See more completed investments
														</Link>
													</div>
												</div>
											</>
										)}
									</div>

									<div className="loans-container">
										{/* Loading */}
										{this.isLoading("COLLECTIONS") && (
											<div className="my-3">{this.loadingBlock()}</div>
										)}

										{/* Zero State */}
										{this.noLoansAreFound("COLLECTIONS") && (
											<div className="zero-state-title">You have no collection loans</div>
										)}

										{/* Loans */}
										{!this.isLoading("COLLECTIONS") && !this.noLoansAreFound("COLLECTIONS") && (
											<>
												<div className="title">Collection loans</div>
												<div className="body">
													<div>
														{collectionsLoansData.loans.results.slice(0, 2).map((item) => (
															<InvestmentCard
																key={item.id}
																data={item}
																loansType="collection"
															/>
														))}
													</div>
													<div className="see-more-loans-link">
														<Link to="/user/loans/collections">
															See more collection loans
														</Link>
													</div>
												</div>
											</>
										)}
									</div>

									<div className="loans-container">
										{/* Loading */}
										{this.isLoading("DEFAULT") && <div className="my-3">{this.loadingBlock()}</div>}

										{/* Zero State */}
										{this.noLoansAreFound("DEFAULT") && (
											<div className="zero-state-title">You have no defaults loans</div>
										)}

										{/* Loans */}
										{!this.isLoading("DEFAULT") && !this.noLoansAreFound("DEFAULT") && (
											<>
												<div className="title">Defaults loans</div>
												<div className="body">
													<div>
														{defaultLoansData.loans.results.slice(0, 2).map((item) => (
															<InvestmentCard
																key={item.id}
																data={item}
																loansType="collection"
															/>
														))}
													</div>
													<div className="see-more-loans-link">
														<Link to="/user/loans/defaults">
															See more defaults loans
														</Link>
													</div>
												</div>
											</>
										)}
									</div>
								</>
							)}

							{/* Processing Tab */}
							{loansType === "processing" && (
								<div className="loans-container">
									{/* Loading */}
									{this.isLoading("PROCESSING") && <div className="my-3">{this.loadingBlock()}</div>}

									{/* Zero State */}
									{this.noLoansAreFound("PROCESSING") && (
										<div className="zero-state-section">
											<div className="zero-state-title">You have no processing loans.</div>

											<div className="image-container">
												<img src={ZeroStateImage} alt="Not Found" />
											</div>
										</div>
									)}

									{/* Loans */}
									{!this.isLoading("PROCESSING") && !this.noLoansAreFound("PROCESSING") && (
										<>
											<div className="title">Processing loans</div>
											<div className="body">
												<div>
													{processingLoansData.loans.results.map((item) => (
														<InvestmentCard
															key={item.id}
															data={item}
															loansType="processing"
														/>
													))}
												</div>

												{processingLoansData.loans.next && (
													<ShowMore
														loading={processingLoansData.loadingMore}
														handleClick={() =>
															this.props.fetchMoreProcessingLoans(
																this.props.processingLoansData.loans.next
															)
														}
													/>
												)}
											</div>
										</>
									)}
								</div>
							)}

							{/* Current Tab */}
							{loansType === "current" && (
								<div className="loans-container">
									{/* Loading */}
									{this.isLoading("CURRENT") && <div className="my-3">{this.loadingBlock()}</div>}

									{/* Zero State */}
									{this.noLoansAreFound("CURRENT") && (
										<div className="zero-state-section">
											<div className="zero-state-title">You have no current loans.</div>

											<div className="image-container">
												<img src={ZeroStateImage} alt="Not Found" />
											</div>
										</div>
									)}

									{/* Loans */}
									{!this.isLoading("CURRENT") && !this.noLoansAreFound("CURRENT") && (
										<>
											<div className="title">Current loans</div>
											<div className="body">
												<div>
													{currentLoansData.loans.results.map((item) => (
														<InvestmentCard key={item.id} data={item} loansType="current" />
													))}
												</div>

												{currentLoansData.loans.next && (
													<ShowMore
														loading={currentLoansData.loadingMore}
														handleClick={() =>
															this.props.fetchMoreCurrentLoans(
																this.props.currentLoansData.loans.next
															)
														}
													/>
												)}
											</div>
										</>
									)}
								</div>
							)}

							{/* Completed Tab */}
							{loansType === "completed" && (
								<div className="loans-container">
									{/* Loading */}
									{this.isLoading("COMPLETED") && <div className="my-3">{this.loadingBlock()}</div>}

									{/* Zero State */}
									{this.noLoansAreFound("COMPLETED") && (
										<div className="zero-state-section">
											<div className="zero-state-title">You have no completed loans.</div>

											<div className="image-container">
												<img src={ZeroStateImage} alt="Not Found" />
											</div>
										</div>
									)}

									{/* Loans */}
									{!this.isLoading("COMPLETED") && !this.noLoansAreFound("COMPLETED") && (
										<>
											<div className="title">Completed loans</div>
											<div className="body">
												<div>
													{completedLoansData.loans.results.map((item) => (
														<InvestmentCard
															key={item.id}
															data={item}
															loansType="completed"
														/>
													))}
												</div>

												{completedLoansData.loans.next && (
													<ShowMore
														loading={completedLoansData.loadingMore}
														handleClick={() =>
															this.props.fetchMoreCompletedLoans(
																this.props.completedLoansData.loans.next
															)
														}
													/>
												)}
											</div>
										</>
									)}
								</div>
							)}

							{/* Collections Tab */}
							{loansType === "collections" && (
								<div className="loans-container">
									{/* Loading */}
									{this.isLoading("COLLECTIONS") && <div className="my-3">{this.loadingBlock()}</div>}

									{/* Zero State */}
									{this.noLoansAreFound("COLLECTIONS") && (
										<div className="zero-state-section">
											<div className="zero-state-title">You have no collections loans.</div>

											<div className="image-container">
												<img src={ZeroStateImage} alt="Not Found" />
											</div>
										</div>
									)}

									{/* Loans */}
									{!this.isLoading("COLLECTIONS") && !this.noLoansAreFound("COLLECTIONS") && (
										<>
											<div className="title">Collection loans</div>

											<div className="mt-2 font-size-14">
												If payments are missed, check back here for updates and status of
												collections. Once collected, payment will be transferred to you
												automatically.
											</div>

											<div className="body">
												<div>
													{collectionsLoansData.loans.results.map((item) => (
														<InvestmentCard
															key={item.id}
															data={item}
															loansType="collection"
														/>
													))}
												</div>

												{collectionsLoansData.loans.next && (
													<ShowMore
														loading={collectionsLoansData.loadingMore}
														handleClick={() =>
															this.props.fetchMoreCollectionsLoans(
																this.props.collectionsLoansData.loans.next
															)
														}
													/>
												)}
											</div>
										</>
									)}
								</div>
							)}

							{/* Default Tab */}
							{loansType === "defaults" && (
								<div className="loans-container">
									{/* Loading */}
									{this.isLoading("DEFAULT") && <div className="my-3">{this.loadingBlock()}</div>}

									{/* Zero State */}
									{this.noLoansAreFound("DEFAULT") && (
										<div className="zero-state-section">
											<div className="zero-state-title">You have no default loans.</div>

											<div className="image-container">
												<img src={ZeroStateImage} alt="Not Found" />
											</div>
										</div>
									)}

									{/* Loans */}
									{!this.isLoading("DEFAULT") && !this.noLoansAreFound("DEFAULT") && (
										<>
											<div className="title">Default loans</div>

											<div className="mt-2 font-size-14">
												If payments are missed, check back here for updates and status of
												default. Once collected, payment will be transferred to you
												automatically.
											</div>

											<div className="body">
												<div>
													{defaultLoansData.loans.results.map((item) => (
														<InvestmentCard
															key={item.id}
															data={item}
															loansType="collection"
														/>
													))}
												</div>

												{defaultLoansData.loans.next && (
													<ShowMore
														loading={defaultLoansData.loadingMore}
														handleClick={() =>
															this.props.fetchMoreDefaultLoans(
																this.props.defaultLoansData.loans.next
															)
														}
													/>
												)}
											</div>
										</>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		userData: state.user.userData,
		completedLoansData: state.requests.completedLoansData,
		currentLoansData: state.requests.currentLoansData,
		processingLoansData: state.requests.processingLoansData,
		collectionsLoansData: state.requests.collectionsLoansData,
		defaultLoansData: state.requests.defaultLoansData,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		fetchCompletedLoans: () => dispatch(fetchCompletedLoans()),
		fetchMoreCompletedLoans: (apiEndpointForMoreLoans) =>
			dispatch(fetchMoreCompletedLoans(apiEndpointForMoreLoans)),
		fetchCurrentLoans: () => dispatch(fetchCurrentLoans()),
		fetchMoreCurrentLoans: (apiEndpointForMoreLoans) => dispatch(fetchMoreCurrentLoans(apiEndpointForMoreLoans)),
		fetchProcessingLoans: () => dispatch(fetchProcessingLoans()),
		fetchMoreProcessingLoans: (apiEndpointForMoreLoans) =>
			dispatch(fetchMoreProcessingLoans(apiEndpointForMoreLoans)),
		fetchCollectionsLoans: () => dispatch(fetchCollectionsLoans()),
		fetchMoreCollectionsLoans: (apiEndpointForMoreLoans) =>
			dispatch(fetchMoreCollectionsLoans(apiEndpointForMoreLoans)),
		fetchDefaultLoans: () => dispatch(fetchDefaultLoans()),
		fetchMoreDefaultLoans: (apiEndpointForMoreLoans) => dispatch(fetchMoreDefaultLoans(apiEndpointForMoreLoans)),
		fetchUserData: () => dispatch(fetchUserData()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InvestmentPage));
