import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchHelpArticlesData, filterHelpArticlesData } from "../../redux";
import _ from "lodash";

// Import Components
import WhiteBox from "../whiteBox/whiteBox";
import LNInput from "../common/LNInput/LNInput";
import MobileNav from "../mobileNav/mobileNav";

// Images
import SearchIcon from "../../img/icons/muted/search.svg";
import SupportLoadingBlock from "../../img/spinners/blocks/settings page.svg";

// Import CSS
import "./supportPage.css";

function SupportPage() {
	const [data, setData] = useState({ q: "" });
	const [limit, setLimit] = useState({
		generalData: 5,
		investorData: 5,
		borrowerData: 5,
	});
	const { helpArticlesData, generalData, investorData, borrowerData } = useSelector((state) => state.support);
	const dispatch = useDispatch();

	// Fetch Help Articles Data
	useEffect(() => {
		dispatch(fetchHelpArticlesData());
	}, [dispatch]);

	// Helper Methods
	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
		dispatch(filterHelpArticlesData(input.value));
	};

	// Check if loading
	if (helpArticlesData.loading)
		return (
			<div className="dashboard-wrapper">
				<div className="dashboard-content">
					<img src={SupportLoadingBlock} alt="Loading..." />
				</div>
			</div>
		);

	// Render
	return (
		<>
			<div className="dashboard-wrapper support-page">
				{/* Mobile Nav */}
				<MobileNav title="Support" />

				<div className="dashboard-content">
					<div className="page-title d-none d-sm-block">Support</div>

					<h5 className="font-weight-bold mt-5 mb-4">Frequently asked questions</h5>

					<div>
						<div className="search-bar-wrapper">
							<img src={SearchIcon} alt="Search" />
							<LNInput
								onChange={handleChange}
								value={data.q}
								name="q"
								inputGroupType="large"
								placeholder="Search"
								removablePlaceholder={true}
							/>
						</div>

						<div className="row">
							<div className="col-12 col-md-6 col-lg-4">
								<div className="boxes-wrapper">
									<div className="title">General</div>

									{/* Desktop */}
									<div className="d-none d-md-block">
										{generalData.data.map((item) => (
											<WhiteBox
												key={item.id}
												text={item.name}
												modalTitle={item.name}
												modalText={item.body}
												modalButttonText="Done"
											/>
										))}
									</div>

									{/* Tablet & Mobile */}
									<div className="d-md-none">
										{_.slice(generalData.data, 0, limit.generalData).map((item) => (
											<WhiteBox
												key={item.id}
												text={item.name}
												modalTitle={item.name}
												modalText={item.body}
												modalButttonText="Done"
											/>
										))}

										<div
											onClick={() =>
												setLimit({
													...limit,
													generalData: limit.generalData === 5 ? generalData.data.length : 5,
												})
											}
											className="white-box justify-content-end font-weight-bold">
											{limit.generalData === 5 && `Show all ${generalData.data.length} articles`}
											{limit.generalData !== 5 && `Show less articles`}
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 col-md-6 col-lg-4">
								<div className="boxes-wrapper">
									<div className="title">Investor</div>

									{/* Desktop */}
									<div className="d-none d-md-block">
										{investorData.data.map((item) => (
											<WhiteBox
												key={item.id}
												text={item.name}
												modalTitle={item.name}
												modalText={item.body}
												modalButttonText="Done"
											/>
										))}
									</div>

									{/* Tablet & Mobile */}
									<div className="d-md-none">
										{_.slice(investorData.data, 0, limit.investorData).map((item) => (
											<WhiteBox
												key={item.id}
												text={item.name}
												modalTitle={item.name}
												modalText={item.body}
												modalButttonText="Done"
											/>
										))}

										<div
											onClick={() =>
												setLimit({
													...limit,
													investorData:
														limit.investorData === 5 ? investorData.data.length : 5,
												})
											}
											className="white-box justify-content-end font-weight-bold">
											{limit.investorData === 5 &&
												`Show all ${investorData.data.length} articles`}
											{limit.investorData !== 5 && `Show less articles`}
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 col-md-6 col-lg-4">
								<div className="boxes-wrapper">
									<div className="title">Borrower</div>

									{/* Desktop */}
									<div className="d-none d-md-block">
										{borrowerData.data.map((item) => (
											<WhiteBox
												key={item.id}
												text={item.name}
												modalTitle={item.name}
												modalText={item.body}
												modalButttonText="Done"
											/>
										))}
									</div>

									{/* Tablet & Mobile */}
									<div className="d-md-none">
										{_.slice(borrowerData.data, 0, limit.borrowerData).map((item) => (
											<WhiteBox
												key={item.id}
												text={item.name}
												modalTitle={item.name}
												modalText={item.body}
												modalButttonText="Done"
											/>
										))}

										<div
											onClick={() =>
												setLimit({
													...limit,
													borrowerData:
														limit.borrowerData === 5 ? borrowerData.data.length : 5,
												})
											}
											className="white-box justify-content-end font-weight-bold">
											{limit.borrowerData === 5 &&
												`Show all ${borrowerData.data.length} articles`}
											{limit.borrowerData !== 5 && `Show less articles`}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SupportPage;
