import React from "react";
import { useLocation, Navigate } from "react-router-dom";

// Import Services
import auth from "../../../services/authService";

const ProtectedRoute = ({ children }) => {

	let location = useLocation();

	const authenticated = auth.getCurrentAccount();

	if (!authenticated) {
		return <Navigate to={{ pathname: "/login", state: { from: location}, }} />;
	}
	 
	return children;

	// return (
	// 	<Route
	// 		path={path}
	// 		element={(props) => {
	// 			if (!auth.getCurrentAccount())
	// 				return (
						// <Route element={<Navigate to={{
						// 	pathname: "/login",
						// 	state: { from: location},
						// }} />} />
	// 				);
	// 				return Component ? <Component {...props} /> : render(props);
	// 		}}
	// 	/>
	// );
};

export default ProtectedRoute;
