
import axios from "axios";
import http from "./httpService";
import { jwtDecode } from "jwt-decode";
import moment from "moment";

const accessTokenKey = "accessToken";
const refreshTokenKey = "refreshToken";
const currentAccessTokenDate = "currentAccessTokenDate";

// Login
export async function login(data) {
		let axiosInstance = axios.create()
		// console.log('login_data ', data);
		const response = await axiosInstance.post("/token/", {
			email: data.email,
			password: data.password,
});

	// Store token in localStorage
	// console.log("login_response ", response);
	console.log("response_data ", response.data);
	// console.log("response_data_access ", JSON.stringify(response.data.access));

	localStorage.setItem(accessTokenKey, response.data.access);
	localStorage.setItem(refreshTokenKey, response.data.refresh);
	const currentDate = moment().utc().format();
	localStorage.setItem(currentAccessTokenDate, currentDate);
}

export async function socialLogin(firebase_token) {
	let axiosInstance = axios.create()

	let axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${firebase_token}`,
		}
	  };
	
	 await axiosInstance.post("/social-token/", null ,axiosConfig
	).then((response)=>{		
		localStorage.setItem(accessTokenKey, response.data.access);
		localStorage.setItem(refreshTokenKey, response.data.refresh);	
		const currentDate = moment().utc().format();
		localStorage.setItem(currentAccessTokenDate, currentDate);
		console.log("response",response)
	}).catch((err)=>{
		console.error("error",err);
		return Promise.reject(err);
	})
	}




// Login With JWT
export function loginWithJwt(accessJWT, refreshJWT) {
	localStorage.setItem(accessTokenKey, accessJWT);
	localStorage.setItem(refreshTokenKey, refreshJWT);
}

// Logout
export function logout() {
	localStorage.removeItem(accessTokenKey);
	localStorage.removeItem(refreshTokenKey);
	localStorage.removeItem(currentAccessTokenDate);
}

// Get Current Account
export function getCurrentAccount() {
	try {
		const jwt = localStorage.getItem(accessTokenKey);
		if (!jwt) return null;
		console.log('jwt ', jwt)
		return jwtDecode(jwt);
	} catch (ex) {
		return null;
	}
}

// Check Auth
export function checkAuth(current) {
	const account = getCurrentAccount();
	if (account && account.user_id === current.user_id) return true;
	return false;
}

export default {
	login,
	loginWithJwt,
	logout,
	getCurrentAccount,
	checkAuth,
	socialLogin
};
