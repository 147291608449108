import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchCompanyData, fetchLicenseData, fetchOwnersData } from "../../redux";

// Import Services
import userService from "../../services/userService";

// Import Utils
import { getRegionList } from "../../utils/handleRegions";

// Import Components
import LNAvatar from "../common/LNAvatar/LNAvatar";
import OwnerCard from "../ownerCard/ownerCard";
import EditPersonalInfo from "../editProfileForms/editPersonalInfo";
import EditCompanyInfo from "../editProfileForms/editCompanyInfo";
import EditLicenseInfo from "../editProfileForms/editLicenseInfo";
import EditOwnershipInfo from "../editProfileForms/editOwnershipInfo";
import MobileNav from "../mobileNav/mobileNav";

// Import Images
import UserProfileLoadingBlock from "../../img/spinners/blocks/profile page.svg";

// Import CSS
import "./userProfile.css";

class UserProfile extends Component {
	state = {
		lendingLicense: {
			file_name: null,
			file: null,
		},
		editing: false,
		editingView: {
			personalInfo: false,
			companyInfo: false,
			licenseInfo: false,
			ownershipInfo: false,
		},
		error: null,
		completeProfileAlert: {
			visible: false,
		},
	};

	// When Mount
	async componentDidMount() {
		// Get User's Company's Data
		console.log('UserProfile_Mounted');
		await this.props.fetchCompanyData();

		// Get User's Lending License
		await this.props.fetchLicenseData();

		const companyType = this.props?.companyData?.company?.type;
		console.log('componentDidMount_companyType ', companyType)
		// Get User's Owners' Data (if not sole)
		if (companyType !== "sole") {

			await this.props.fetchOwnersData();
		}

		// Get User's Lending License file
		if (this.props.licenseData.license.file) {
			this.getLendingLicenseURL(this.props.licenseData.license.file);
		}

		// Show complete profile info alert
		if (this.props.userData.user.registration_step !== 6) {
			this.showCompleteProfileAlert();
		}
	}

	// Helper Methods
	handleShowView = (name) => {
		const editingView = { ...this.state.editingView };
		const editing = true;
		editingView[name] = true;
		this.setState({ editing, editingView });
	};

	handleHideView = (name) => {
		const editingView = { ...this.state.editingView };
		const editing = false;
		editingView[name] = false;
		this.setState({ editing, editingView });
	};

	capitalizeFirstLetter = (word) => {
		if (!word) return '';
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	};

	getLendingLicenseURL = async (file_id) => {
		try {
			const fileData = await userService.getFile(file_id);
			const lendingLicense = { file_name: fileData.data.file_name, file: fileData.data.file };
			this.setState({ lendingLicense });
		} catch (error) {
			this.setState({ error: error.detail });
		}
	};

	getStateName = (state_code) => {
		const stateList = getRegionList("US");
		const state = stateList.find((state) => state.value === state_code);
		if (state) return state.name;
		return "";
	};

	showCompleteProfileAlert = () => {
		const completeProfileAlert = { ...this.state.completeProfileAlert };
		completeProfileAlert.visible = true;
		this.setState({ completeProfileAlert });
	};

	handleStepRemaining = () => {
		const { userData, companyData } = this.props;
		const companyType = companyData.company?.type;
		const registrationStep = userData.user?.registration_step ?? 0;

		switch (companyType) {
			case "sole":
				return (5 - registrationStep).toString();
			default:
				return (6 - registrationStep).toString();
		}
	};

	render() {
		const { userData, companyData, licenseData, ownersData } = this.props;
		const { lendingLicense, editing, editingView, error, completeProfileAlert } = this.state;
		const { user } = userData;
		const { company } = companyData;
		console.log("company ", company)
		console.log("company__ssn", company.ssn)
		const { license } = licenseData;
		const { owners } = ownersData;

		// Check if loading
		if (userData.loading || companyData.loading || licenseData.loading || (company.type !== 'sole' && ownersData.loading)) {
			return (
				<div className="dashboard-wrapper">
					<div className="dashboard-content">
						<img src={UserProfileLoadingBlock} alt="Loading..." className="w-100" />
					</div>
				</div>
			);
		}

		// Check if company error
		if (error) {
			return <div>{error.response.data.detail}</div>;
		}

		// Render
		return (
			<div className="dashboard-wrapper">
				{/* Mobile Nav */}
				<MobileNav title="Profile" />

				{/* Profile Main Page */}
				{!editing && (
					<div className="dashboard-content mb-5">
						<div className="profile-section">
							{/* Avatar Section */}
							<div className="d-flex align-items-center flex-row">
								<LNAvatar data={[{ icon: user.avatar, color: 4 }]} />
								<div className="profile-welcome-text ml-3">
									Hooray {this.capitalizeFirstLetter(user.first_name)}!
								</div>
							</div>

							{/* Complete Profile Alert */}
							{completeProfileAlert.visible && (
								<div className="row align-items-end complete-profile-alert mt-3">
									<div className="col-12 col-sm-8">
										<div className="title">{this.handleStepRemaining()} {this.handleStepRemaining() === '1' ? 'step' : 'steps'} remaining</div>
										<div className="message">Let's complete your profile and start investing</div>
									</div>
									<div className="col-12 col-sm-4 mt-3 mt-sm-0 d-sm-flex justify-content-end">
										<button
											onClick={() => (window.location = "/signup-process")}
											className="btn btn-sm px-4">
											Update profile
										</button>
									</div>
								</div>
							)}
						</div>

						{/* Personal Info section */}
						<div className="profile-section">
							<div className="d-flex flex-row align-items-baseline">
								<div className="profile-section-title">Personal Information</div>
								{company.is_verified && <div className="profile-section-verified ml-3">Verified</div>}
							</div>

							<div className="row">
								<div className="col-12 col-sm-6">
									<div className="info-wrapper">
										<div className="label">First and Middle Name</div>
										<div className="value">
											{this.capitalizeFirstLetter(user.first_name)}{" "}
											{this.capitalizeFirstLetter(user.middle_name)}
										</div>
									</div>
								</div>

								<div className="col-12 col-sm-6">
									<div className="info-wrapper">
										<div className="label">Last Name</div>
										<div className="value">{this.capitalizeFirstLetter(user.last_name)}</div>
									</div>
								</div>
							</div>

							{/* Edit */}
							{!company.is_verified && (
								<div className="edit-button">
									<button
										className="btn btn-black btn-sm px-4"
										onClick={() => {
											this.handleShowView("personalInfo");
										}}>
										Edit
									</button>
								</div>
							)}
						</div>

						{/* Company Info section */}
						{!companyData.error && (
							<div className="profile-section">
								<div className="d-flex flex-row align-items-baseline">
									<div className="profile-section-title">Company Information</div>
									{company.is_verified && (
										<div className="profile-section-verified ml-3">Verified</div>
									)}
								</div>

								<div className="row">
									<div className="col-12 col-sm-6">
										<div className="info-wrapper">
											<div className="label">Company Name</div>
											<div className="value">{this.capitalizeFirstLetter(company.name)}</div>
										</div>
									</div>
									<div className="col-12 col-sm-6">
										<div className="info-wrapper">
											<div className="label">Company Type</div>
											<div className="value">{this.capitalizeFirstLetter(company.type)}</div>
										</div>
									</div>
									<div className="col-12 col-sm-6">
										<div className="info-wrapper">
											<div className="label">Doing Business As</div>
											<div className="value">{this.capitalizeFirstLetter(company.dba)}</div>
										</div>
									</div>
									{company.type === "sole" && company?.ssn && (
										<div className="col-12 col-sm-6">
											<div className="info-wrapper">
												<div className="label">SSN</div>
												<div className="value">•••-•••-{company.ssn?.slice(-4)}</div>
											</div>
										</div>
									)}
									<div className="col-12 col-sm-6">
										<div className="info-wrapper">
											<div className="label">Company Address 1</div>
											<div className="value">
												{this.capitalizeFirstLetter(company.street_address_1)}
											</div>
										</div>
									</div>
									<div className="col-12 col-sm-6">
										<div className="info-wrapper">
											<div className="label">Company Address 2</div>
											<div className="value">
												{this.capitalizeFirstLetter(company.street_address_2)}
											</div>
										</div>
									</div>
									<div className="col-12 col-sm-6">
										<div className="info-wrapper">
											<div className="label">City</div>
											<div className="value">{this.capitalizeFirstLetter(company.city)}</div>
										</div>
									</div>
									<div className="col-12 col-sm-6">
										<div className="info-wrapper">
											<div className="label">State</div>
											<div className="value">{this.getStateName(company.state)}</div>
										</div>
									</div>
									<div className="col-12 col-sm-6">
										<div className="info-wrapper">
											<div className="label">Zip Code</div>
											<div className="value">{company.zip_code}</div>
										</div>
									</div>
									<div className="col-12 col-sm-6">
										<div className="info-wrapper">
											<div className="label">Company Phone Number</div>
											<div className="value">{company.phone}</div>
										</div>
									</div>
									{company.website && (
										<div className="col-12 col-sm-6">
											<div className="info-wrapper">
												<div className="label">Company Website</div>
												<div className="value">{company.website}</div>
											</div>
										</div>
									)}
								</div>

								{/* Edit */}
								{!company.is_verified && (
									<div className="edit-button">
										<button
											className="btn btn-black btn-sm px-4"
											onClick={() => {
												this.handleShowView("companyInfo");
											}}>
											Edit
										</button>
									</div>
								)}
							</div>
						)}

						{/* Ownership Info section */}
						{!(company.type === "sole") && !ownersData.error && (
							<div className="profile-section">
								<div className="d-flex flex-row align-items-baseline">
									<div className="profile-section-title">Ownership Information</div>
									{company.is_verified && (
										<div className="profile-section-verified ml-3">Verified</div>
									)}
								</div>

								<div>
									{owners.count > 0 && (
										<div className="d-flex pb-3">
											Total number of owners
											<div className="font-weight-bold pl-1">{owners.count}</div>
										</div>
									)}

									<div className="row">
										{owners.results.map((owner) => {
											return (
												<div className="col-12 col-sm-6">
													<OwnerCard
														key={owner.id}
														owner={owner}
														update_function={() => this.props.fetchOwnersData()}
													/>
												</div>
											);
										})}
									</div>

									<div className="body-text d-flex pt-3">
										Total Ownership:
										<div className="total-owners font-weight-bold pl-1">
											{owners.results.reduce(
												(sum, owner) => sum + (parseInt(owner["ownership_percentage"]) || 0),
												0
											)}
											%
										</div>
									</div>
								</div>

								{/* Edit */}
								{owners.results.reduce(
									(sum, owner) => sum + (parseInt(owner["ownership_percentage"]) || 0),
									0
								) <= 75 &&
									!company.is_verified && (
										<div className="edit-button">
											<button
												className="btn btn-black btn-sm px-4"
												onClick={() => {
													this.handleShowView("ownershipInfo");
												}}>
												Edit
											</button>
										</div>
									)}
							</div>
						)}

						{/* Lending License Section */}
						<div className="profile-section mb-5">
							<div className="d-flex flex-row align-items-baseline">
								<div className="profile-section-title">Lending License</div>
								{/* {company.is_verified && <div className="profile-section-verified ml-3">Verified</div>} */}
							</div>

							{/* Lending License Found section */}
							{!licenseData?.error && (
								<>
									<div className="row">
										<div className="col-12 col-sm-6">
											<div className="info-wrapper">
												<div className="label">State of Issuance</div>
												<div className="value">
													{this.getStateName(license.state_of_issuance)}
												</div>
											</div>
										</div>
										<div className="col-12 col-sm-6">
											<div className="info-wrapper">
												<div className="label">License Type</div>
												<div className="value d-flex">
													{this.capitalizeFirstLetter(license.type)}{" "}
													<a
														href={lendingLicense.file}
														target="_blank"
														rel="noopener noreferrer"
														className="ml-3 link">
														{lendingLicense.file_name}
													</a>
												</div>
											</div>
										</div>
										<div className="col-12 col-sm-6">
											<div className="info-wrapper">
												<div className="label">License Number</div>
												<div className="value">{license.number}</div>
											</div>
										</div>
										<div className="col-12 col-sm-6">
											<div className="info-wrapper">
												<div className="label">Issue Date</div>
												<div className="value">{license.issue_date}</div>
											</div>
										</div>
										<div className="col-12 col-sm-6">
											<div className="info-wrapper">
												<div className="label">Expiry Date</div>
												<div className="value">{license.expiry_date}</div>
											</div>
										</div>
									</div>

									{/* Edit */}
									<div className="edit-button d-none">
										<button className="btn btn-black btn-sm px-4">Edit</button>
									</div>
								</>
							)}

							{/* Lending License not Found */}
							{licenseData?.error && licenseData?.error?.response?.status === 404 && (
								<div className="row align-items-center">
									<div className="col-12 col-sm-7">
										<div className="text-muted">Do you have a lending or broker license?</div>
									</div>

									<div className="col-12 col-sm-5 d-sm-flex justify-content-end mt-3 mt-sm-0">
										<div
											className="btn btn-black btn-sm px-4"
											onClick={() => {
												this.handleShowView("licenseInfo");
											}}>
											Add Lending License
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				)}

				{/* Editing View */}
				{editing && (
					<div className="dashboard-content">
						{editingView.companyInfo && (
							<EditCompanyInfo
								reset_view_func={() => {
									this.handleHideView("companyInfo");
								}}
							/>
						)}
						{editingView.personalInfo && (
							<EditPersonalInfo
								reset_view_func={() => {
									this.handleHideView("personalInfo");
								}}
							/>
						)}
						{editingView.licenseInfo && (
							<EditLicenseInfo
								reset_view_func={() => {
									this.handleHideView("licenseInfo");
								}}
							/>
						)}
						{editingView.ownershipInfo && (
							<EditOwnershipInfo
								reset_view_func={() => {
									this.handleHideView("ownershipInfo");
								}}
							/>
						)}
					</div>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		userData: state.user.userData,
		companyData: state.user.companyData,
		licenseData: state.user.licenseData,
		ownersData: state.user.ownersData,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		fetchCompanyData: () => dispatch(fetchCompanyData()),
		fetchLicenseData: () => dispatch(fetchLicenseData()),
		fetchOwnersData: () => dispatch(fetchOwnersData()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
