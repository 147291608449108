import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import TrashIcon from '../../img/assets/trash-02.svg'
import "./AutomationCondition.css";

function AutomationCondition({
  id, 
  conditionNum, 
  handleClick, 
  conditionOptions, 
  handleSelectChange, 
  handleRemoveCondition, 
  handleChangeOptions, 
  alreadyExistedOptions, 
  alreadyExistedConditionOption, 
  selectedConditionOptions,
  selectedLoanType
}) 
{

  const [conditionMin, setConditionMin] = useState('');
  const [conditionMax, setConditionMax] = useState('');
  
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [availableOptions, setAvailableOptions] = useState()
  const [selectedOption, setSelectedOption] = useState(null)
  const [lenmePredictionSelectedOption, setLenmePredictionSelectedOption] = useState(null)

  const initialOptions = [
    { value: 'credit_score', label: 'Credit score', id: 1, min: 350, max: 850 },
    { value: 'payback_period', label: 'Payback period', id: 2, min: 1, max: 12 },
    { value: 'number_of_previous_loans', label: 'Number of previous loans', id: 3, min: 1, max: '9+' },
    { value: 'payment_history', label: 'Payment history', id: 4, min: 0, max: 100 },
    { value: 'credit_utlization', label: 'Credit utlization', id: 5, min: 0, max: 100 },
    { value: 'hard_inquiry', label: 'Hard inquiry', id: 6, min: 0, max: '9+' },
    { value: 'total_accounts', label: 'Total accounts', id: 7, min: 0, max: '9+' },
    { value: 'annual_income', label: 'Annual income', id: 8, min: 0, max: '150k+' },
    { value: 'deregatory_mark', label: 'Deregatory mark', id: 9, min: 0, max: '9+' },
    { value: 'lenmo_score', label: 'Lenme score', id: 10, min: 0, max: 100 },
    { value: 'LenmePrediction', label: 'Lenme Prediction', id: 11, LenmePrediction: 'Likely to Pay' },
    { value: 'lenme_predict_confidence', label: 'Lenme predict confidence', id: 12, min: 0, max: 100 },
    { value: 'avg_credit', label: 'Avg credit', id: 13, min: 0, max: '5000+' },
    { value: 'avg_debit', label: 'Avg debit', id: 14, min: 0, max: '5000+' },
    { value: 'salus_score', label: 'Salus Score', id: 15, min: 0, max: 100 },
    { value: 'loan_amount', label: 'Loan Amount', id: 16, min: 50, max: selectedLoanType === 'cash' ? 5000 : 10000 },
  ];
  const initialOptionsPlaceHolder = [
    { id: 1, min: '350', max: '850' },
    { id: 2, min: '1', max: '12' },
    { id: 3, min: '1', max: '9+' },
    { id: 4, min: '0%', max: '100%' },
    { id: 5, min: '0%', max: '100%' },
    { id: 6, min: '0', max: '9+' },
    { id: 7, min: '0', max: '9+' },
    { id: 8, min: '$0', max: '$50000+' },
    { id: 9, min: '0', max: '9+' },
    { id: 10, min: '0', max: '100' },
    { id: 11, LenmePrediction: 'Likely to Pay' },
    { id: 12, min: '0%', max: '100%' },
    { id: 13, min: '$0', max: '$5000+' },
    { id: 14, min: '$0', max: '$5000+' },
    { id: 15, min: '0', max: '100' },
    { id: 16, min: '$50', max: selectedLoanType === 'cash' ? '$5000' : '$10000' },
  ];
  const percentageOptionsIds = [4, 5, 12];

  const dollarOptionsIds = [8, 13, 14, 16];

  function isValidMaxNumber(option) {
    console.log('isValidMaxNumber_option ', option)
    const validMaxRegex = /^(\d+|\d+\.\d+)$/; // Matches whole numbers or decimal numbers without trailing zeros

    // Check if the 'max' property exists and matches the regex pattern
    const result = validMaxRegex.test(option)
    console.log('isValidMaxNumber ', result)
    return result;

  }
  // // 14 options and not reusable so we need to remove the used ones from the options array
  // const [conditionOptions, setConditionOptions] = useState([
  //   { value: 'credit_score', label: 'credit score' },
  //   { value: 'payback_period', label: 'payback period' },
  //   { value: 'number of previous loans', label: 'number of previous loans' },
  //   { value: 'payment history', label: 'payment history' },
  //   { value: 'credit utlization', label: 'credit utlization' },
  //   { value: 'hard inquiry', label: 'hard inquiry' },
  //   { value: 'total accounts', label: 'total accounts' },
  //   { value: 'annual income', label: 'annual income' },
  //   { value: 'deregatory mark', label: 'deregatory mark' },
  //   { value: 'lenmo score', label: 'lenmo score' },
  //   { value: 'LenmePrediction', label: 'Lenme Prediction' },
  //   { value: 'lenme predict confidence', label: 'lenme predict confidence' },
  //   { value: 'avg credit', label: 'avg credit' },
  //   { value: 'avg debit', label: 'avg debit' }
  // ]);

  // const [conditionOptions1, setConditionOptions1] = useState([...conditionOptions]);

  // const handleSelectChange = (selectedOption) => {
  //   // Update state for the second react-select
  //   setConditionOptions1((prevOptions) =>
  //     prevOptions.filter((option) => option.value !== selectedOption.value)
  //   );

  //   // Handle other logic for the second react-select if needed
  // };

  useEffect(() => {
    console.log('AutomationCondition')
    console.log('conditionOptions ', conditionOptions)
    console.log('conditionNum ', conditionNum)
    console.log('selectedConditionOptions____ ', selectedConditionOptions)
    // console.log('conditionOptions ', conditionOptions)
    console.log('conditionMin ', conditionMin)
    console.log('conditionMax ', conditionMax)

  }, [conditionOptions, selectedConditionOptions, conditionMin])

  useEffect(() => {
    // execlude the selectedConditionOptions from all the conditionOptions
    if (selectedConditionOptions && selectedConditionOptions?.length > 0) {

      const updatedConditionOptions = conditionOptions.filter(
        (optionObject) => 
           !selectedConditionOptions.some(
             (selectedOption) => selectedOption.value === optionObject.value
           )
      );
      console.log('updatedConditionOptions ', updatedConditionOptions)
      setAvailableOptions(updatedConditionOptions)
      
    }
    else {
      setAvailableOptions(conditionOptions)
    }
  }, [selectedConditionOptions, conditionOptions])

  useEffect(() => {
    if (alreadyExistedOptions && !isEmptyObj(alreadyExistedOptions) && alreadyExistedConditionOption){
      console.log('alreadyExistedOptions ', alreadyExistedOptions)
      console.log('alreadyExistedConditionOption ', alreadyExistedConditionOption)
      let foundObject = initialOptions.find(item => item.value === alreadyExistedConditionOption);
      if (foundObject != null) {
        console.log('foundObject ', foundObject)

        const ـalreadyExistedConditionOption = foundObject.label
        console.log('ـalreadyExistedConditionOption ', ـalreadyExistedConditionOption)
        setSelectedOption(foundObject)
        handleSelectChange(foundObject, conditionNum, id)

        for (let key in alreadyExistedOptions) {
          if (key.startsWith('min_')) {
            console.log(`Key: ${key}, Value: ${alreadyExistedOptions[key]}`);
            if (percentageOptionsIds.includes(foundObject?.id)) {
              console.log('percentageOptionsIds_foundObject_includes_foundObject?.id ', foundObject?.id)
              setConditionMin(`${alreadyExistedOptions[key]}%`)
            } 
            else if (dollarOptionsIds.includes(foundObject?.id)) {
              console.log('dollarOptionsIds_foundObject_includes_foundObject?.id ', foundObject?.id)
              setConditionMin(`$${alreadyExistedOptions[key]}`)
            } 
            else {
              setConditionMin(`${alreadyExistedOptions[key]}`)
            }
          }
          else if (key.startsWith('max_')) {
            console.log(`Key: ${key}, Value: ${alreadyExistedOptions[key]}`);
            if (percentageOptionsIds.includes(foundObject?.id)) {
              console.log('percentageOptionsIds_foundObject_includes_foundObject?.id ', foundObject?.id)
              setConditionMax(`${alreadyExistedOptions[key]}%`)
            } 
            else if (dollarOptionsIds.includes(foundObject?.id)) {
              console.log('dollarOptionsIds_foundObject_includes_foundObject?.id ', foundObject?.id)
              setConditionMax(`$${alreadyExistedOptions[key]}`)
            }
            else {
              setConditionMax(`${alreadyExistedOptions[key]}`)
            }
          }
          else if (key === 'LenmePrediction') {
            console.log(`Key: ${key}, Value: ${alreadyExistedOptions[key]}`);
            setLenmePredictionSelectedOption([{value: alreadyExistedOptions[key], label: alreadyExistedOptions[key]}])
          }
        }
        // setConditionMin(alreadyExistedOptions)
        // handleChangeOptions({min: value}, conditionNum)
      }
      // setSelectedOption()
    }
  }, [alreadyExistedOptions, alreadyExistedConditionOption])

  function isEmptyObj(obj) {
    return Object.keys(obj).length === 0;
  }

  const handleKeyDown = (event, setterFun, getterFun, key) => {
    console.log('handleKeyDown_getterFun ', getterFun)
    console.log('handleKeyDown_event_value ', event.target.value)
    if (event.key === 'Backspace' || event.key === 'Delete') {
      console.log('getterFun?.length ', getterFun?.length)

      if (getterFun?.length === 2 && getterFun?.includes('%')) {
        console.log('if')
        if (key === 'min') setConditionMin('')
        else setConditionMax('')
        // setterFun('');
        handleChangeOptions(null, conditionNum, id, key + '_' + selectedOption?.value)
      } 
      else {
        console.log('else')
        // setterFun('');
        if (key === 'min') setConditionMin('')
        else setConditionMax('')
        // setterFun((prevValue) => prevValue.slice(0, -1));
        handleChangeOptions(null, conditionNum, id, key + '_' + selectedOption?.value)

        // setterFun((prevValue) => prevValue.substring(0, getterFun.length - 1));
        // const vall = getterFun.substring(0, getterFun.length - 1)
        // console.log('vall ', vall)
        // handleChangeOptions({[key]: vall}, conditionNum, id)
      }
      console.log('handleKeyDown_getterFun_conditionMin ', conditionMin)
      console.log('handleKeyDown_getterFun_conditionMax ', conditionMax)
    }
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', marginTop: '16px'}}>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline',   backgroundColor: '#FFF', padding: 16, border: '1px solid #D4D4D4'}}>
      <p style={{marginRight: '12px'}}>Condition {conditionNum && conditionNum}</p>
      <Select
        // className="basic-single"
        // isMulti
        classNamePrefix="select"
        // defaultValue={conditionOptions[0]}
        // value={}
        value={selectedOption}
        className='react-select'
        onChange={(val) => {
          if (val) {

            console.log('onChange_val ', val)
            setSelectedOption(val)
            handleSelectChange(val, conditionNum, id)
            setConditionMin('')
            setConditionMax('')
            // if (val?.id === 4 || val?.id === 5 || val?.id === 10 || val?.id === 12) {
            //   setConditionMin(0)
            //   setConditionMax(100)
            // }
            if (val?.id === 11) {
              setLenmePredictionSelectedOption([{value: 'Likely to Pay', label: 'Likely to Pay'}])
              handleChangeOptions({LenmePrediction: val?.LenmePrediction}, conditionNum, id)
            } else {
              if (lenmePredictionSelectedOption) {
                setLenmePredictionSelectedOption(null)
                handleChangeOptions(null, conditionNum, id, 'all')
              } else {
                handleChangeOptions(null, conditionNum, id, 'all')
              }
              // handleChangeOptions({LenmePrediction: val?.LenmePrediction}, conditionNum)
            }
          }
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={false}
        // isRtl={isRtl}
        isSearchable={isSearchable}
        name="color"
        options={availableOptions}
      />
      {conditionNum && conditionNum > 1 ? (
        <div style={{marginLeft: 'auto', cursor: 'pointer'}} onClick={() => {
          console.log('selectedOption ', selectedOption)
          console.log('conditionNum ', conditionNum)

          handleRemoveCondition(selectedOption, conditionNum, id)
        }}>

          <img src={TrashIcon} alt="remove icon"  />
        </div>
      ): null}
    </div>
    {/* Seperator */}
    {/* <div style={{display: 'flex', flexDirection: 'row', backgroundColor: '#D4D4D4', height: '1px'}} /> */}

    {selectedOption?.value !== "LenmePrediction" ? (

      <div style={{display: 'flex', flexDirection: 'row', backgroundColor: '#FFF', padding: 16, border: '1px solid #D4D4D4', borderTop: 0}}>
        <div style={{display: 'flex', flexDirection: 'column', marginRight: '21px'}}>
          <p className='condition'>
            <span className='star'>*</span>
            Minimum</p>
            <div className='input-condition-container'>
              
              <input
                className={'input-condition'}
                // style={{width: '120px', height: '32px'}}
                type={'text'}
                required
                // pattern="[0-100]"
                // min={Number(selectedOption?.min)}
                // max={isValidMaxNumber(selectedOption?.max) ? 850 : undefined}
                id={'Name'}
                value={conditionMin}
                name={'name'}
                placeholder={initialOptionsPlaceHolder[selectedOption?.id - 1]?.min}
                onChange={(e) => {
                  // console.log('onChange_called')
                  console.log('onChange_value', e.target.value)
                  let value = e.target.value;
                  // Remove non-digit characters
                  value = value.replace(/\D/g, '');
                  // Remove % character
                  if (value.includes('%')) {
                    console.log('value_before_slice ', value)
                    value = value.replace(/%/g, ''); // This line removes all '%' characters
                    console.log('value_after_slice ', value)
                  }
                  // Remove all zeros except the first one
                  value = value.replace(/^0+(?=0)/, '');

                  if (conditionMin === "0" && value === "0") return;
                  console.log('value ', value)
                  if (value === "") return;

                  if (isValidMaxNumber(value)) {
                    if (Number(value) > Number(selectedOption?.max)) return;
                  }
                  // const validNum =  ? selectedOption?.max : null
                  
                  if (percentageOptionsIds.includes(selectedOption?.id)) {
                    if (value < 0 || value > 100) return;

                    console.log('percentageOptionsIds_includes_value ', value)
                    setConditionMin(`${value}%`)
                    handleChangeOptions({min: value}, conditionNum, id)
                  }
                  else if (dollarOptionsIds.includes(selectedOption?.id)) {
                    console.log('dollarOptionsIds_includes_value ', value)
                    setConditionMin(`$${value}`)
                    handleChangeOptions({min: value}, conditionNum, id)
                  }
                  else {
                    console.log('percentageOptionsIds_includes_else_value ', value)
                    setConditionMin(value)
                    // if (value === '') handleChangeOptions(null, conditionNum, id, 'min' + '_' + selectedOption?.value)
                    // else 
                    handleChangeOptions({min: value}, conditionNum, id)
                  }
                }}
                onKeyDown={(e) => handleKeyDown(e, setConditionMin, conditionMin, 'min')}
                // disabled={disabled}
              />
              {/* <span className='percent-symbol'>%</span> */}
            </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <p className='condition'>
            <span className='star'>*</span>
            Maximum</p>

          <div className='input-condition-container'>
            <input
              className={'input-condition'}
              type={'text'}
              required
              // pattern="[0-9]"
              // min={conditionMin ? Number(conditionMin) + 1 : selectedOption?.min}
              // max={isValidMaxNumber(selectedOption?.max) ? Number(selectedOption?.max) : undefined}
              id={'Name'}
              value={conditionMax}
              name={'name'}
              placeholder={initialOptionsPlaceHolder[selectedOption?.id - 1]?.max}
              onChange={(e) => {
                
                // console.log('value', e.target.value)
                let value = e.target.value;
                // Remove non-digit characters
                value = value.replace(/\D/g, '');
                // Remove % character
                if (value.includes('%')) {
                  console.log('value_before_slice ', value)
                  value = value.replace(/%/g, ''); // This line removes all '%' characters
                  console.log('value_after_slice ', value)
                }
                // Remove all zeros except the first one
                value = value.replace(/^0+(?=0)/, '');

                if (conditionMin === "0" && value === "0") return;
                console.log('value ', value)
                if (value === "") return;

                if (isValidMaxNumber(value)) {
                  if (Number(value) > Number(selectedOption?.max)) return;
                }
                
                if (percentageOptionsIds.includes(selectedOption?.id)) {
                  if (value < 1 || value > 100) return;

                  console.log('percentageOptionsIds_includes_value ', value)
                  setConditionMax(`${value}%`)
                  handleChangeOptions({max: value}, conditionNum, id)
                }
                else if (dollarOptionsIds.includes(selectedOption?.id)) {
                  console.log('dollarOptionsIds_includes_value ', value)
                  setConditionMax(`$${value}`)
                  handleChangeOptions({max: value}, conditionNum, id)
                }
                else {

                  setConditionMax(value)
                  handleChangeOptions({max: value}, conditionNum, id)
                }
              }}
              onKeyDown={(e) => handleKeyDown(e, setConditionMax, conditionMax, 'max')}
              // disabled={disabled}
            />
            {/* <span className='percent-symbol'>{conditionMax}%</span> */}
          </div>
        </div>



      </div>
    )
    : 
    (
      <div style={{display: 'flex', flexDirection: 'row', backgroundColor: '#FFF', padding: 16, border: '1px solid #D4D4D4', borderTop: 0}}>
        <div style={{display: 'flex', flexDirection: 'column', marginRight: '21px'}}>
          <p className='condition'>
            <span className='star'>*</span>
            Lenme Prediction</p>
          {/* <input
            className={'input-condition'}
            style={{width: '200px'}}
            type={'text'}
            // required
            // pattern="[0-9]"
            // min={0}
            maxLength={15}
            // id={'Name'}
            // value={conditionMin}
            // name={'name'}
            placeholder={'Lenme Prediction'}
            onChange={(e) => {
              // console.log('value', e.target.value)
              let value = e.target.value;
              // Remove non-digit characters
              // value = value.replace(/\D/g, '');

              // Ensure it's a single digit
              // value = value.slice(0, 1);
              // setConditionMin(value)
              // handleChangeOptions({min: value}, conditionNum)
            }}
            // disabled={disabled}
          /> */}
          <Select
            // className="basic-single"
            // isMulti
            classNamePrefix="select"
            // defaultValue={conditionOptions[0]}
            // value={}
            value={lenmePredictionSelectedOption}
            className='react-select'
            onChange={(val) => {
              if (val) {

                console.log('onChange_val ', val)
                setLenmePredictionSelectedOption(val)
                handleChangeOptions({LenmePrediction: val.value}, conditionNum, id)
              }
            }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={false}
            // isRtl={isRtl}
            isSearchable={isSearchable}
            name="color"
            options={[{value: 'Likely to Pay', label: 'Likely to Pay'}, {value: 'Unlikely to Pay', label: 'Unlikely to Pay'}]}
          />
        </div>
      </div>
    )
    }
    {/* <div style={{ display: 'flex', marginTop: '24px'}}>
      <button type='button' className='button-submit' onClick={() => handleClick(conditionNum)} >
        <p style={{margin: 'auto'}} >Add Condition</p>
      </button>
    </div> */}
  </div>
  )
}

export default AutomationCondition