import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchOwnersData, addUserOwner } from "../../redux";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";

// Import Services

// Import Utils
import { getRegionList } from "../../utils/handleRegions";
import { getCountryList } from "../../utils/handleCountries";

// Import Components
import LNFloatingInput from "../common/LNFloatingInput/LNFloatingInput";
import LNSelect from "../common/LNSelect/LNSelect";
import LNRadioInput from "../common/LNRadioInput/LNRadioInput";
import LNDatePicker from "../common/LNDatePicker/LNDatePicker";
import LNInputMask from "../common/LNInputMask/LNInputMask";
import LNUpload from "../common/LNUpload/LNUpload";
import OwnerCard from "../ownerCard/ownerCard";

// Import Images
import ArrowRight from "../../img/icons/black/arrow-right.svg";
import AddIcon from "../../img/icons/blue/add-icon.png";

// Import CSS
import "./editOwnershipInfo.css";

class editOwnershipInfo extends Component {
	state = {
		totalOwnershipPercentage: 0,
		addingOwner: false,
	};

	componentDidMount() {
		this.calculateTotalOwnershipPercentage();
	}

	// Helper Methods
	getOwners() {
		this.props.fetchOwnersData();
	}

	calculateTotalOwnershipPercentage() {
		var totalOwnershipPercentage = parseInt(0, 10);
		for (var item of this.props.ownersData.owners.results) {
			var ownership_percentage = parseInt(item.ownership_percentage, 10);
			totalOwnershipPercentage += ownership_percentage;
		}
		this.setState({
			totalOwnershipPercentage: totalOwnershipPercentage,
		});
	}

	render() {
		// Validations
		const ValidationSchema = Yup.object().shape({
			first_name: Yup.string()
				.max(255, "Too Long!")
				.required("Required field"),
			middle_name: Yup.string().max(255, "Too Long!"),
			last_name: Yup.string()
				.max(255, "Too Long!")
				.required("Required field"),
			title: Yup.string()
				.max(10, "Too Long!")
				.required("Required field"),
			ownership_percentage: Yup.number()
				.lessThan(101, "Your input must be between 25 and 100 percent.")
				.moreThan(24, "Your input must be between 25 and 100 percent."),
			dob: Yup.string().test("dob", "You need to be atleast 18 years old.", value => {
				return moment().diff(moment(value), "years") >= 18;
			}),
			ssn: Yup.string()
				.min(9, "SSN Should be 9 digits.")
				.max(9, "SSN Should be 9 digits.")
				.required("Required field"),
			street_address_1: Yup.string()
				.max(255, "Too Long!")
				.required("Required field"),
			street_address_2: Yup.string().max(255, "Too Long!"),
			city: Yup.string()
				.max(255, "Too Long!")
				.required("Required field"),
			state: Yup.string().required("Required field"),
			zip_code: Yup.string()
				.trim()
				.matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Please enter a valid U.S. zipcode.")
				.required("Required field"),
			passport_number: Yup.string()
				.min(6, "Passport should be atleast 6 characters.")
				.max(9, "Passport should be no more than 9 characters."),
			passport_country: Yup.string(),
			country: Yup.string().required("Required field"),
		});

		const { addingOwner } = this.state;
		const { ownersData, addUserOwner, reset_view_func } = this.props;

		// Check if loading
		if (ownersData.loading) return <div>Loading...</div>;

		// Render
		return (
			<div>
				<div className="d-flex flex-row align-items-center">
					<div
						className="profile-page-link"
						onClick={() => {
							reset_view_func();
						}}>
						Profile Page
					</div>
					<img src={ArrowRight} alt="Arrow Right" className="profile-page-arrow" />
					<div className="profile-page-link-name">Ownership Information</div>
				</div>

				{/* Viewing Owners View */}
				{!addingOwner && (
					<div className="row mt-5">
						<div className="col-9 offset-2">
							<div className="profile-section-title">Ownership Information</div>

							<div className="inputs-wrapper">
								{this.props.ownersData.owners.results.length > 0 && (
									<div className="body-text d-flex pb-3">
										Total number of owners
										<div className="total-owners font-weight-bold pl-1">
											{this.props.ownersData.owners.results.length}
										</div>
									</div>
								)}
								<div className="row">
									{this.props.ownersData.owners.results.map(owner => {
										return (
											<div className="col-6">
												<OwnerCard
													key={owner.id}
													owner={owner}
													update_function={this.getOwners}
												/>
											</div>
										);
									})}
								</div>

								{this.state.totalOwnershipPercentage < 75 && (
									<button
										className="btn btn-lg btn-outline-white btn-icon-text btn-block text-left"
										onClick={() => {
											//Shows Form view
											this.setState({ addingOwner: true });
										}}>
										<img src={AddIcon} alt="+" />
										Add an owner
									</button>
								)}
								{this.state.totalOwnershipPercentage > 0 && (
									<div className="body-text d-flex pt-3">
										Total Ownership:
										<div className="total-owners font-weight-bold pl-1">
											{this.state.totalOwnershipPercentage}%
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
				{/* Adding Owners View */}
				{addingOwner && (
					<div className="row mt-5">
						<div className="col-6 offset-2">
							<div className="profile-section-title">Add a new owner</div>

							<div>
								<Formik
									initialValues={{
										first_name: "",
										middle_name: "",
										last_name: "",
										title: "",
										ownership_percentage: "",
										dob: "",
										ssn: "",
										street_address_1: "",
										street_address_2: "",
										city: "",
										state: "",
										zip_code: "",
										passport_number: "",
										passport_country: "",
										country: "US",
										file: "",
										identification_type: "",
									}}
									validationSchema={ValidationSchema}
									onSubmit={async (values, { setSubmitting, setErrors }) => {
										setSubmitting(true);
										const postData = {
											first_name: values.first_name,
											middle_name: values.middle_name,
											last_name: values.last_name,
											title: values.title,
											ownership_percentage: values.ownership_percentage,
											dob: values.dob,
											ssn: values.ssn,
											street_address_1: values.street_address_1,
											street_address_2: values.street_address_2,
											city: values.city,
											state: values.state,
											zip_code: values.zip_code,
											passport_number: values.passport_number,
											passport_country: values.passport_country,
											country: values.country,
											file: values.file,
										};
										Object.keys(postData).forEach(key => {
											if (!postData[key]) delete postData[key];
										});
										try {
											await addUserOwner(postData);
											setSubmitting(false);
											this.getOwners();
											this.setState({ adding: false });
										} catch (ex) {
											console.log("Error: ", ex);
											setErrors(ex.response.data);
											setSubmitting(false);
										}
									}}>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										setFieldValue,
										setSubmitting,
									}) => (
										<form onSubmit={handleSubmit} spellCheck={false}>
											<div className="inputs-wrapper">
												<LNFloatingInput
													size="lg"
													type="text"
													name="first_name"
													value={values.first_name}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder="First Name"
													error={
														touched.first_name && errors.first_name
															? `* ${errors.first_name}`
															: ""
													}
												/>

												<LNFloatingInput
													size="lg"
													type="text"
													name="middle_name"
													optional={true}
													value={values.middle_name}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder="Middle Name"
													error={
														touched.middle_name && errors.middle_name
															? `* ${errors.middle_name}`
															: ""
													}
												/>

												<LNFloatingInput
													size="lg"
													type="text"
													name="last_name"
													value={values.last_name}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder="Last Name"
													error={
														touched.last_name && errors.last_name
															? `* ${errors.last_name}`
															: ""
													}
												/>

												<LNFloatingInput
													size="lg"
													type="text"
													name="title"
													value={values.title}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder="Job title"
													error={touched.title && errors.title ? `* ${errors.title}` : ""}
												/>

												<LNFloatingInput
													size="lg"
													type="text"
													name="ownership_percentage"
													value={values.ownership_percentage}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder="Ownership %"
													error={
														touched.ownership_percentage && errors.ownership_percentage
															? `* ${errors.ownership_percentage}`
															: ""
													}
												/>

												<LNDatePicker
													size="lg"
													optional={false}
													name="dob"
													value={values.dob}
													placeholder="Date of birth"
													dateFormat="MM/dd/yyyy"
													onChange={date =>
														setFieldValue("dob", moment(date).format("YYYY-MM-DD"))
													}
													error={touched.dob && errors.dob ? `* ${errors.dob}` : ""}
												/>

												{values.country === "US" && (
													<LNInputMask
														mask="999-99-9999"
														maskChar="X"
														value={values.ssn}
														onChange={e => {
															const value =
																e.target.value.replace(/-/g, "").replace(/X/g, "") ||
																"";
															setFieldValue("ssn", value);
														}}
														onBlur={handleBlur}
														size="lg"
														type="text"
														name="ssn"
														placeholder="Social security number"
														error={touched.ssn && errors.ssn ? `* ${errors.ssn}` : ""}
													/>
												)}

												<LNFloatingInput
													size="lg"
													type="text"
													name="street_address_1"
													value={values.street_address_1}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder="Address"
													error={
														touched.street_address_1 && errors.street_address_1
															? `* ${errors.street_address_1}`
															: ""
													}
												/>

												<LNFloatingInput
													size="lg"
													type="text"
													optional={true}
													name="street_address_2"
													value={values.street_address_2}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder="Address 2"
													error={
														touched.street_address_2 && errors.street_address_2
															? `* ${errors.street_address_2}`
															: ""
													}
												/>

												<LNFloatingInput
													size="lg"
													type="text"
													name="city"
													value={values.city}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder="City"
													error={touched.city && errors.city ? `* ${errors.city}` : ""}
												/>

												<LNSelect
													name="state"
													size="lg"
													value={values.state}
													onChange={handleChange}
													onBlur={handleBlur}
													defaultOption={{ name: "State", value: "" }}
													options={getRegionList(values.country)}
												/>

												<LNInputMask
													mask="99999"
													maskChar="X"
													value={values.zip_code}
													onChange={e => {
														const value = e.target.value
															.replace(/-/g, "")
															.replace(/X/g, "");
														setFieldValue("zip_code", value);
													}}
													onBlur={handleBlur}
													size="lg"
													type="text"
													name="zip_code"
													placeholder="Zip Code"
													error={
														touched.zip_code && errors.zip_code
															? `* ${errors.zip_code}`
															: ""
													}
												/>

												<LNSelect
													name="country"
													size="lg"
													value={values.country}
													onChange={handleChange}
													onBlur={handleBlur}
													defaultOption={{ name: "Country", value: "" }}
													options={getCountryList()}
												/>

												{values.country === "US" && (
													<>
														<span className="text-muted d-block mb-3">ID Document</span>

														<div className="mb-2">
															<div className="mb-2">
																<LNRadioInput
																	name="identification_type"
																	label="ID"
																	value="idCard"
																	onChange={handleChange}
																/>
															</div>

															{values.identification_type === "idCard" && (
																<>
																	<LNUpload
																		document_type="idCard"
																		change_function={file_id => {
																			setFieldValue("file", file_id);
																		}}
																		set_loading={state => {
																			setSubmitting(state);
																		}}
																		existing_file={
																			this.state.editing
																				? this.state.existingFileName
																				: ""
																		}></LNUpload>
																</>
															)}

															<div className="mb-2">
																<LNRadioInput
																	name="identification_type"
																	label="Driver's License"
																	value="license"
																	onChange={handleChange}
																/>
															</div>
															{values.identification_type === "license" && (
																<>
																	<LNUpload
																		document_type="license"
																		change_function={file_id => {
																			setFieldValue("file", file_id);
																		}}
																		set_loading={state => {
																			setSubmitting(state);
																		}}
																		existing_file={
																			this.state.editing
																				? this.state.existingFileName
																				: ""
																		}></LNUpload>
																</>
															)}
														</div>
													</>
												)}

												{values.country !== "US" && (
													<>
														<span className="text-muted d-block mb-3">Passport</span>

														<LNUpload
															document_type="passport"
															change_function={file_id => {
																setFieldValue("file", file_id);
															}}
															set_loading={state => {
																setSubmitting(state);
															}}
														/>

														<LNInputMask
															mask="999999999"
															maskChar="X"
															value={values.passport_number}
															onChange={handleChange}
															onBlur={handleBlur}
															size="lg"
															type="text"
															name="passport_number"
															placeholder="Passport Number"
															error={
																touched.passport_number && errors.passport_number
																	? `* ${errors.passport_number}`
																	: ""
															}
														/>

														<LNSelect
															name="passport_country"
															size="lg"
															value={values.passport_country}
															onChange={handleChange}
															onBlur={handleBlur}
															defaultOption={{ name: "Passport Country", value: "" }}
															options={getCountryList()}
														/>
													</>
												)}
											</div>
											<div
												className="btn btn-lg btn-black w-100 my-3"
												onClick={handleSubmit}
												disabled={isSubmitting}>
												Save
											</div>
										</form>
									)}
								</Formik>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		ownersData: state.user.ownersData,
	};
}
function mapDispatchToProps(dispatch) {
	return {
		fetchOwnersData: () => dispatch(fetchOwnersData()),
		addUserOwner: owner_data => dispatch(addUserOwner(owner_data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(editOwnershipInfo);
