import moment from "moment";

// Utils
import { handleBalanceFormat } from "../../utils/handleBalanceFormat";
import { handleFloatingLimit } from "../../utils/handleFloatingLimit";

// Types
import {
	FETCH_USER_DATA_REQUEST,
	FETCH_USER_DATA_SUCCESS,
	FETCH_USER_DATA_FAILURE,
	FETCH_USER_COMPANY_REQUEST,
	FETCH_USER_COMPANY_SUCCESS,
	FETCH_USER_COMPANY_FAILURE,
	FETCH_USER_LICENSE_REQUEST,
	FETCH_USER_LICENSE_SUCCESS,
	FETCH_USER_LICENSE_FAILURE,
	FETCH_USER_OWNERS_REQUEST,
	FETCH_USER_OWNERS_SUCCESS,
	FETCH_USER_OWNERS_FAILURE,
	UPDATE_USER_PROFILE_REQUEST,
	UPDATE_USER_PROFILE_SUCCESS,
	UPDATE_USER_PROFILE_FAILURE,
	UPDATE_USER_COMPANY_REQUEST,
	UPDATE_USER_COMPANY_SUCCESS,
	UPDATE_USER_COMPANY_FAILURE,
	ADD_USER_OWNER_REQUEST,
	ADD_USER_OWNER_SUCCESS,
	ADD_USER_OWNER_FAILURE,
	FETCH_AGGREGATION_DATA_REQUEST,
	FETCH_AGGREGATION_DATA_SUCCESS,
	FETCH_AGGREGATION_DATA_FAILURE,
	FETCH_CURRENT_BALANCE_REQUEST,
	FETCH_CURRENT_BALANCE_SUCCESS,
	FETCH_CURRENT_BALANCE_FAILURE,
	FETCH_AVERAGE_RATE_OF_RETURN_REQUEST,
	FETCH_AVERAGE_RATE_OF_RETURN_SUCCESS,
	FETCH_AVERAGE_RATE_OF_RETURN_FAILURE,
	FETCH_CURRENT_LOAN_REQUEST,
	FETCH_CURRENT_LOAN_SUCCESS,
	FETCH_CURRENT_LOAN_FAILURE,
	FETCH_TOTAL_INVESTED_REQUEST,
	FETCH_TOTAL_INVESTED_SUCCESS,
	FETCH_TOTAL_INVESTED_FAILURE,
	FETCH_TOTAL_EARNED_REQUEST,
	FETCH_TOTAL_EARNED_SUCCESS,
	FETCH_TOTAL_EARNED_FAILURE,
} from "./types";

// Initial State
const initialState = {
	userData: {
		loading: true,
		user: {},
		error: "",
	},
	companyData: {
		loading: true,
		company: {},
		error: "",
	},
	licenseData: {
		loading: true,
		license: {},
		error: "",
	},
	ownersData: {
		loading: true,
		owners: {},
		error: "",
	},
	updateProfileData: {
		loading: true,
		error: "",
	},
	aggregationData: {
		loading: true,
		data: {},
		error: "",
	},
	currentBalance: {
		loading: true,
		data: {},
		error: "",
	},
	averageRateOfReturn: {
		loading: true,
		data: {},
		error: "",
	},
	currentLoan: {
		loading: true,
		data: {},
		error: "",
	},
	totalInvested: {
		loading: true,
		data: {},
		error: "",
	},
	totalEarned: {
		loading: true,
		data: {},
		error: "",
	},
};

// Reducer
const reducer = (state = initialState, action) => {
	switch (action.type) {
		// User Data
		case FETCH_USER_DATA_REQUEST:
			return {
				...state,
				userData: { ...state.userData, loading: true },
			};
		case FETCH_USER_DATA_SUCCESS:
			return {
				...state,
				userData: { ...state.userData, loading: false, user: action.payload },
			};
		case FETCH_USER_DATA_FAILURE:
			return {
				...state,
				userData: { ...state.userData, loading: false, error: action.payload },
			};

		// Company Data
		case FETCH_USER_COMPANY_REQUEST:
			return {
				...state,
				companyData: { ...state.companyData, loading: true },
			};
		case FETCH_USER_COMPANY_SUCCESS:
			function companyWithMaskedPhone() {
				const company = { ...action.payload };

				if (company.phone.trim() !== "") {
					company.phone = company.phone.substring(2);
					var USNumber = company.phone.match(/(\d{3})(\d{3})(\d{4})/);
					company.phone = USNumber[1] + "-" + USNumber[2] + "-" + USNumber[3];
				}
				console.log('FETCH_USER_COMPANY_SUCCESS_company ', company)
				return company;
			}

			console.log('FETCH_USER_COMPANY_SUCCESS_companyWithMaskedPhone ', companyWithMaskedPhone())

			return {
				...state,
				companyData: { ...state.companyData, loading: false, company: companyWithMaskedPhone() },
			};
		case FETCH_USER_COMPANY_FAILURE:
			return {
				...state,
				companyData: { ...state.companyData, loading: false, error: action.payload },
			};

		// License Data
		case FETCH_USER_LICENSE_REQUEST:
			return {
				...state,
				licenseData: { ...state.licenseData, loading: true },
			};
		case FETCH_USER_LICENSE_SUCCESS:
			return {
				...state,
				licenseData: { ...state.licenseData, loading: false, license: action.payload },
			};
		case FETCH_USER_LICENSE_FAILURE:
			return {
				...state,
				licenseData: { ...state.licenseData, loading: false, error: action.payload },
			};

		// Owners Data
		case FETCH_USER_OWNERS_REQUEST:
			return {
				...state,
				ownersData: { ...state.ownersData, loading: true },
			};
		case FETCH_USER_OWNERS_SUCCESS:
			return {
				...state,
				ownersData: { ...state.ownersData, loading: false, owners: action.payload },
			};
		case FETCH_USER_OWNERS_FAILURE:
			return {
				...state,
				ownersData: { ...state.ownersData, loading: false, error: action.payload },
			};

		// Update User Profile
		case UPDATE_USER_PROFILE_REQUEST:
			return {
				...state,
				updateProfileData: { ...state.updateProfileData, loading: true },
			};
		case UPDATE_USER_PROFILE_SUCCESS:
			return {
				...state,
				userData: { ...state.userData, user: { ...state.userData.user, ...action.payload } },
				updateProfileData: { ...state.updateProfileData, loading: false },
			};
		case UPDATE_USER_PROFILE_FAILURE:
			return {
				...state,
				updateProfileData: { ...state.updateProfileData, loading: false, error: action.payload },
			};

		// Update User Company
		case UPDATE_USER_COMPANY_REQUEST:
			return {
				...state,
				updateCompanyData: { ...state.updateCompanyData, loading: true },
			};
		case UPDATE_USER_COMPANY_SUCCESS:
			console.log('Before update:', state);
  			console.log('Action payload:', action.payload);
			const nextState = {
				...state,
				companyData: {
				  ...state.companyData,
				  company: {
					...state.companyData.company,
					...action.payload
				  }
				},
				updateCompanyData: {
				  ...state.updateCompanyData,
				  loading: false
				},
			};
			
			console.log('After update:', nextState);
			return nextState;
			// return {
			// 	...state,
			// 	companyData: { ...state.companyData, company: { ...state.companyData.company, ...action.payload } },
			// 	updateCompanyData: { ...state.updateCompanyData, loading: false },
			// };
		case UPDATE_USER_COMPANY_FAILURE:
			return {
				...state,
				updateCompanyData: { ...state.updateCompanyData, loading: false, error: action.payload },
			};

		// Add User owner
		case ADD_USER_OWNER_REQUEST:
			return {
				...state,
				ownersData: { ...state.ownersData, loading: true },
			};
		case ADD_USER_OWNER_SUCCESS:
			return {
				...state,
				ownersData: { ...state.ownersData, owners: { ...action.payload } },
			};
		case ADD_USER_OWNER_FAILURE:
			return {
				...state,
				ownersData: { ...state.ownersData, loading: false, error: action.payload },
			};

		// Fetch Aggregation Data
		case FETCH_AGGREGATION_DATA_REQUEST:
			return {
				...state,
				aggregationData: { ...state.aggregationData, loading: true },
			};
		case FETCH_AGGREGATION_DATA_SUCCESS:
			function calculateAllAggregationGrarphPoints() {
				const aggregationData = { ...action.payload };

				// One Month
				aggregationData.OneMonth.total_pending = handleBalanceFormat(aggregationData.OneMonth.total_pending);
				aggregationData.OneMonth.total_paid = handleBalanceFormat(aggregationData.OneMonth.total_paid);
				// Three Month
				aggregationData.ThreeMonth.total_pending = handleBalanceFormat(
					aggregationData.ThreeMonth.total_pending
				);
				aggregationData.ThreeMonth.total_paid = handleBalanceFormat(aggregationData.ThreeMonth.total_paid);
				// Six Month
				aggregationData.SixMonth.total_pending = handleBalanceFormat(aggregationData.SixMonth.total_pending);
				aggregationData.SixMonth.total_paid = handleBalanceFormat(aggregationData.SixMonth.total_paid);
				// One Year
				aggregationData.OneYear.total_pending = handleBalanceFormat(aggregationData.OneYear.total_pending);
				aggregationData.OneYear.total_paid = handleBalanceFormat(aggregationData.OneYear.total_paid);
				// All Data
				aggregationData.AllData.total_pending = handleBalanceFormat(aggregationData.AllData.total_pending);
				aggregationData.AllData.total_paid = handleBalanceFormat(aggregationData.AllData.total_paid);

				// Graph Points
				function getDatesRange(startDate, endDate) {
					let dates = [];
					const start = new Date(startDate);
					const end = new Date(endDate);

					while (start < end) {
						dates = [...dates, new Date(start)];
						start.setDate(start.getDate() + 1);
					}

					return dates;
				}

				const newGraphPoints = [];

				// Check if not empty array
				if (aggregationData.graph_points.length > 0) {
					for (let point of aggregationData.graph_points) {
						const i = aggregationData.graph_points.indexOf(point);
						let range = [];

						// If not the last item
						if (i + 1 < aggregationData.graph_points.length) {
							range = getDatesRange(
								aggregationData.graph_points[i].date,
								aggregationData.graph_points[i + 1].date
							);
						}

						// If last item
						if (i + 1 === aggregationData.graph_points.length) {
							const today = moment().format("YYYY-MM-DD");

							// Check if the date = today
							if (aggregationData.graph_points[i].date === today) {
								range = [new Date(today)];
							}

							// Check if the date is earlier
							else {
								range = getDatesRange(
									aggregationData.graph_points[i].date,
									moment().add(1, "days").format("YYYY-MM-DD")
								);
							}
						}

						// Convert dates into our format and push dates and values to the new graph points array
						for (let date of range) {
							newGraphPoints.push({
								date: moment(date, "YYYY-MM-DD"),
								value: aggregationData.graph_points[i].value,
							});
						}
					}
				}

				aggregationData.graph_points = [...newGraphPoints];
				return aggregationData;
			}

			return {
				...state,
				aggregationData: {
					...state.aggregationData,
					loading: false,
					data: calculateAllAggregationGrarphPoints(),
				},
			};
		case FETCH_AGGREGATION_DATA_FAILURE:
			return {
				...state,
				aggregationData: {
					...state.aggregationData,
					loading: false,
					error: action.payload,
				},
			};

		// Fetch Current Balance
		case FETCH_CURRENT_BALANCE_REQUEST:
			return {
				...state,
				currentBalance: { ...state.currentBalance, loading: true },
			};
		case FETCH_CURRENT_BALANCE_SUCCESS:
			function handleCurrentBalanceData() {
				const data = { ...action.payload };
				if (data.balance) data.balance = handleBalanceFormat(data.balance);
				else data.balance = 0;
				return data;
			}

			return {
				...state,
				currentBalance: {
					...state.currentBalance,
					loading: false,
					data: handleCurrentBalanceData(),
				},
			};
		case FETCH_CURRENT_BALANCE_FAILURE:
			return {
				...state,
				currentBalance: {
					...state.currentBalance,
					loading: false,
					error: action.payload,
				},
			};

		// Fetch Average Rate Of Return
		case FETCH_AVERAGE_RATE_OF_RETURN_REQUEST:
			return {
				...state,
				averageRateOfReturn: { ...state.averageRateOfReturn, loading: true },
			};
		case FETCH_AVERAGE_RATE_OF_RETURN_SUCCESS:
			function handleAverageRateOfReturnData() {
				const data = { ...action.payload };
				if (data.average_rate) data.average_rate = handleFloatingLimit(data.average_rate, 2);
				else data.average_rate = 0;
				return data;
			}

			return {
				...state,
				averageRateOfReturn: {
					...state.averageRateOfReturn,
					loading: false,
					data: handleAverageRateOfReturnData(),
				},
			};
		case FETCH_AVERAGE_RATE_OF_RETURN_FAILURE:
			return {
				...state,
				averageRateOfReturn: {
					...state.averageRateOfReturn,
					loading: false,
					error: action.payload,
				},
			};

		// Fetch Current Loan
		case FETCH_CURRENT_LOAN_REQUEST:
			return {
				...state,
				currentLoan: { ...state.currentLoan, loading: true },
			};
		case FETCH_CURRENT_LOAN_SUCCESS:
			function handleCurrentLoanData() {
				const data = { ...action.payload };
				if (!data.amount) data.amount = 0;
				return data;
			}

			return {
				...state,
				currentLoan: {
					...state.currentLoan,
					loading: false,
					data: handleCurrentLoanData(),
				},
			};
		case FETCH_CURRENT_LOAN_FAILURE:
			return {
				...state,
				currentLoan: {
					...state.currentLoan,
					loading: false,
					error: action.payload,
				},
			};

		// Fetch Total Invested
		case FETCH_TOTAL_INVESTED_REQUEST:
			return {
				...state,
				totalInvested: { ...state.totalInvested, loading: true },
			};
		case FETCH_TOTAL_INVESTED_SUCCESS:
			function handleTotalInvestedData() {
				const data = { ...action.payload };
				if (data.amount) data.amount = handleBalanceFormat(data.amount);
				else data.amount = 0;
				return data;
			}

			return {
				...state,
				totalInvested: {
					...state.totalInvested,
					loading: false,
					data: handleTotalInvestedData(),
				},
			};
		case FETCH_TOTAL_INVESTED_FAILURE:
			return {
				...state,
				totalInvested: {
					...state.totalInvested,
					loading: false,
					error: action.payload,
				},
			};

		// Fetch Total Earned
		case FETCH_TOTAL_EARNED_REQUEST:
			return {
				...state,
				totalEarned: { ...state.totalEarned, loading: true },
			};
		case FETCH_TOTAL_EARNED_SUCCESS:
			function handleTotalEarneddData() {
				const data = { ...action.payload };
				if (data.amount) data.amount = handleBalanceFormat(data.amount);
				else data.amount = 0;
				return data;
			}

			return {
				...state,
				totalEarned: {
					...state.totalEarned,
					loading: false,
					data: handleTotalEarneddData(),
				},
			};
		case FETCH_TOTAL_EARNED_FAILURE:
			return {
				...state,
				totalEarned: {
					...state.totalEarned,
					loading: false,
					error: action.payload,
				},
			};

		default:
			return state;
	}
};

export default reducer;
