import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import * as d3 from "d3";

// CSS
import "./investorGraph.css";

// Init Graph
let dataInit = [];
const height = 80;
const strokeWidth = 2.5;
const markerRadius = 5;
const legendWidth = 88;
let line, data, x, y;

function initGraph(width) {
	x = d3
		.scaleUtc()
		.domain(d3.extent(data, (d) => d.date))
		.range([0, width]);

	y = d3
		.scaleLinear()
		.domain([0, d3.max(data, (d) => d.value)])
		.nice()
		.range([height, 0]);

	line = d3
		.line()
		.defined((d) => !isNaN(d.value))
		.x((d) => x(d.date))
		.y((d) => y(d.value))(data);
}

function InvestorGraph({ updateCurrentBalance, updateCurrentInterval }) {
	const [selectedTimeInterval, setSelectedTimeInterval] = useState("All");
	const [timeIntervals] = useState(["1M", "3M", "6M", "1Y", "All"]);
	const [width, setWidth] = useState(1);
	const aggregationData = useSelector((state) => state.user.aggregationData.data);

	// Refs
	const marker = useRef(null);
	const bar = useRef(null);
	const legend = useRef(null);
	const graphBox = useRef(null);

	// Init graph data
	useEffect(() => {
		if (aggregationData && aggregationData?.graph_points) { 
			// List all graph points in dataInit variable
			aggregationData.graph_points.forEach((i) => {
				dataInit.push({
					date: i.date,
					value: i.value,
				});
			});

			// Slice the last 30 points only
			data = dataInit;
		}

		// Clean dataInit variable when unmounting this component
		return function cleanup() {
			dataInit = [];
		};

		// eslint-disable-next-line
	}, []);

	// Adjust the graph width
	useEffect(() => {
		// Check if no date retrieved
		if (aggregationData.graph_points.length === 0) {
			if (marker && bar) {
				marker.current.classList += "d-none";
				bar.current.classList += "d-none";
			}
			return;
		}

		function adjustGraphWidth() {
			setWidth(graphBox.current?.offsetWidth);
			initGraph(width);
		}
		adjustGraphWidth();

		update(new Date(data[data.length - 1].date));
		updateCurrentInterval(selectedTimeInterval);

		window.removeEventListener("resize", adjustGraphWidth);
		window.addEventListener("resize", adjustGraphWidth);

		return function cleanup() {
			window.removeEventListener("resize", adjustGraphWidth);
		};

		// eslint-disable-next-line
	}, [width]);

	// Helper Methods
	const bisect = d3.bisector((d) => d.date);

	const update = (date) => {
		const i = bisect.left(data, date);
		if (i < data.length) {
			// Update marker
			updateMarker(data[i].date, data[i].value);

			// Update legend
			updateLegend(data[i].date);

			// Update current balance
			updateCurrentBalance(data[i].value, data[0]?.value);
		}
	};

	const handleMove = function (event) {
		const m = d3.pointer(event, this);
		if (data.length) update(x.invert(m[0]));
	};

	const handleLeave = function () {
		if (data.length) update(new Date(data[data.length - 1].date));
	};

	const updateMarker = (date, value) => {
		const xPoint = x(date);
		const yPoint = y(value);

		marker.current.style.top = yPoint - markerRadius + "px";
		marker.current.style.left = xPoint - markerRadius + "px";
		bar.current.style.left = xPoint + "px";
	};

	const updateLegend = (date) => {
		const xPoint = x(date);
		const halfLegendWidth = legendWidth / 2;
		const adjustmentRatio = 10;

		// Adjust legend position

		// Adjust right
		if (xPoint > width - halfLegendWidth + adjustmentRatio)
			legend.current.style.left = xPoint - legendWidth + adjustmentRatio + "px";
		// Adjust left
		else if (xPoint < halfLegendWidth - adjustmentRatio)
			legend.current.style.left = xPoint - adjustmentRatio + "px";
		// Adjust center
		else legend.current.style.left = xPoint - halfLegendWidth + "px";

		legend.current.innerText = `${date.toString()?.split(" ")[2]} ${date.toString()?.split(" ")[1]} ${
			date.toString()?.split(" ")[3]
		}`;
	};

	const setTimeInterval = (i) => {
		setSelectedTimeInterval(i);

		switch (i) {
			case "1M":
				data = dataInit.slice(dataInit.length - 30, dataInit.length);
				break;
			case "3M":
				data = dataInit.slice(dataInit.length - 90, dataInit.length);
				break;
			case "6M":
				data = dataInit.slice(dataInit.length - 180, dataInit.length);
				break;
			case "1Y":
				data = dataInit.slice(dataInit.length - 365, dataInit.length);
				break;
			case "All":
				data = dataInit;
				break;
			default:
				break;
		}

		initGraph(width);
		if (data.length) update(new Date(data[data.length - 1].date));

		// Send the current interval value to dashboard component
		updateCurrentInterval(i);
	};

	return (
		<div className="chart-container">
			<div
				onTouchMove={handleMove}
				onMouseMove={handleMove}
				onMouseLeave={handleLeave}
				onTouchEnd={handleLeave}
				ref={graphBox}>
				<span className="bar" ref={bar}>
					<div className="position-relative">
						<span />
						<span />
					</div>
				</span>

				<svg
					viewBox={`0 0 ${width} ${height}`}
					xmlns="http://www.w3.org/2000/svg"
					width={width}
					height={height}>
					<path fill="transparent" stroke="#131526" strokeWidth={strokeWidth} d={line} />
				</svg>

				<span className="marker" ref={marker} />
				<span className="legend" ref={legend} />
			</div>

			<div className="time-interval d-flex justify-content-around align-items-center mt-2">
				{timeIntervals.map((i) => (
					<span
						key={i}
						className={selectedTimeInterval === i ? "active default" : "pointer"}
						onClick={() => setTimeInterval(i)}>
						{i}
					</span>
				))}
			</div>
		</div>
	);
}

export default InvestorGraph;
