import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchFavLoanRequests, fetchMoreFavLoanRequests, fetchClearFavLoanRequests } from "../../redux";

// Bootstrap Components
import { Modal } from "react-bootstrap";

// Components
import RequestCard from "../requestCard/requestCard";
import ShowMore from "../common/showMore/showMore";

// Images
import RequestsEmpty from "../../img/static/requests_empty.svg";
import LoanCardsLoadingBlocks from "../../img/spinners/blocks/loan cards.svg";
import FlipMove from 'react-flip-move';
// Import CSS
import "./likedRequests.css";

function LikedRequests() {
	const [modalVisible, setModalVisible] = useState(false);
	const [loanRequestsLoading, setLoanRequestsLoading] = useState(true)
	const [loanRequestss, setLoanRequestss] = useState([]);
	const loanRequests = useSelector((state) => state.dashboard.favLoanRequests);
	const _favoriteLoan = useSelector((state) => state.dashboard.favoriteLoan);
	const _unfavoriteLoan = useSelector((state) => state.dashboard.unfavoriteLoan);
	
	const { results: requests = [] } = loanRequests.data;
	const dispatch = useDispatch();

	// When Mount
	useEffect(() => {
		dispatch(fetchFavLoanRequests());

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		setLoanRequestss(requests)
		
	}, []);

	useEffect(() => {
		// console.log('_favoriteLoan ', _favoriteLoan)
		// console.log('_unfavoriteLoan ', _unfavoriteLoan)
		if (!_favoriteLoan.loading || !_unfavoriteLoan.loading) {
			setTimeout(() => {

				setLoanRequestss(requests)
			}, 200)
		}
	}, [_favoriteLoan, _unfavoriteLoan, requests])

	useEffect(() => {
		
		if (loanRequests?.loading) {
			setLoanRequestsLoading(true);
			setTimeout(() => {
			  console.log('Check completed after 1 second');
			  setLoanRequestsLoading(false);
			}, 1000);
		}
	 }, [loanRequests]);

	// Helper Methods
	const handleOpenModal = () => {
		setModalVisible(true);
	};

	const handleCloseModal = () => {
		setModalVisible(false);
	};

	useEffect(()=>{
        document.addEventListener("refreshFavLoans", () => dispatch(fetchFavLoanRequests()));
    },[])


	const clearAllFavLoans = () => {
		dispatch(fetchClearFavLoanRequests());
		handleCloseModal();
	};

	// Check if loading
	if (loanRequestsLoading) {
		// console.log('loanRequests ', loanRequests)
		// console.log('requests ', requests)
		// console.log('favLoanRequests ', favLoanRequests)
		
		return (
			<div className="mt-5 ">
				<div className="d-flex justify-content-end">

					<div className="p-2" style={{width: 'max-content', height: '24px'}}>

					</div>
				</div>
				{requests?.slice(0, 5)?.map((request) => (
					<>
					
					<div key={request?.id} className="card request p-3 mb-3" style={{width: '100%', height: '163px', }}>
						<div className="d-flex justify-content-start">

							<div className="d-flex justify-content-center position-relative mr-3">

								<div className="avatar" style={{background: '#F1F2FB', borderRadius: '50%'}}>

								</div>
								{/* {initials_01 && <div className="avatar-initials px-2">{initials_01}</div>} */}
								<div className="avatar-initials ml-0" style={{background: '#F1F2FB', }}></div>
							</div>
							<div className="d-flex flex-wrap">
								<div className="card-item my-2">
									<div className="request-sub-title" style={{width: '60px', height: '18px', background: '#F1F2FB', borderRadius: '7px', marginBottom: '1px'}}></div>
									<div style={{width: '70px', height: '20px', background: '#F1F2FB', borderRadius: '7px'}}></div>
								</div>
								<div className="card-item my-2">
									<div className="request-sub-title" style={{width: '60px', height: '18px', background: '#F1F2FB', borderRadius: '7px', marginBottom: '1px'}}></div>
									<div style={{width: '70px', height: '20px', background: '#F1F2FB', borderRadius: '7px'}}></div>
								</div>
								<div className="card-item my-2">
									<div className="request-sub-title" style={{width: '60px', height: '18px', background: '#F1F2FB', borderRadius: '7px', marginBottom: '1px'}}></div>
									<div className="d-flex align-items-center">
										{/* <img src={creditScoreIcon} alt={credit_score} /> */}
										{/* <span className="ml-2">{credit_score}</span> */}
										<div style={{width: '10px', height: '10px', borderRadius: '50%', background: '#F1F2FB'}}></div>
										<span className="ml-2" style={{width: '70px', height: '20px', background: '#F1F2FB', borderRadius: '7px'}}></span>
									</div>
								</div>
								<div className="card-item my-2">
									{/* <div className="request-sub-title">Lenme Score</div> */}
									<div className="request-sub-title" style={{width: '60px', height: '18px', background: '#F1F2FB', borderRadius: '7px', marginBottom: '1px'}}></div>
									{/* <div>32</div> */}
									<div style={{width: '70px', height: '20px', background: '#F1F2FB', borderRadius: '7px'}}></div>
								</div>
							</div>
						</div>
					</div>
					</>
				))}
			</div>
		);
	}

	// Zero State
	if (requests.length === 0) {
		return (
			<div className="requests-zero-state mt-3">
				<div>We were unable to find any results.</div>
				<div>Please adjust your search.</div>
				<img className="pt-5" src={RequestsEmpty} alt="No Requests Found" />
			</div>
		);
	}

	// Render
	return (
		<div>
			<div className="d-flex justify-content-end">
				<div className="clear-all-favs p-2 mt-3 pointer" onClick={handleOpenModal}>
					Clear all
				</div>
			</div>
			<FlipMove
				staggerDurationBy="20"
				duration={500}
				enterAnimation="elevator"
				leaveAnimation="elevator"
				typeName="div"
				style={{margin: 0, padding: 0}}
			>
			{loanRequestss.map((item) => (
				<RequestCard key={item.id} data={item} favRequests={item} />
			))}
			</FlipMove>

			{loanRequests.data.next && (
				<ShowMore
					loading={loanRequests.paginate.loading}
					handleClick={() => dispatch(fetchMoreFavLoanRequests())}
				/>
			)}

			{/* Clear Fav Loans Modal */}
			<Modal show={modalVisible} onHide={handleCloseModal} dialogClassName="bank-transfer-modal">
				<Modal.Body>
					<div className="d-flex align-items-center flex-wrap position-relative p-3">
						<div className="modal-close pointer" onClick={handleCloseModal}>
							Close
						</div>

						<div className="bank-transfer-link-success-title p-3 flex-grow-1 w-100">
							Clear favorites list!?
						</div>

						<div className="p-3">Your list of favorite loan requests will be permanantly deleted!</div>

						<div className="w-100 justify-content-center d-flex p-3">
							<div className="btn btn-lg text-danger flex-grow-1" onClick={handleCloseModal}>
								Cancel
							</div>

							<div className="btn btn-lg btn-black flex-grow-1" onClick={clearAllFavLoans}>
								Clear
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default LikedRequests;
