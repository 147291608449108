import axios from "axios";
import {log as sentry_log} from "./logService";
import refreshTokenInterceptor from "./refreshTokenService";
import auth from "./authService";

// Keys
const accessTokenKey = "accessToken";
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
	failedQueue.forEach(prom => {
	  if (error) {
		prom.reject(error);
	  } else {
		prom.resolve(token);
	  }
	});
  
	failedQueue = [];
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Interceptors Configuration
axios.interceptors.request.use(async request => {
	const isLoggedIn = auth.getCurrentAccount();
	if (!isLoggedIn) return request;
	
	const accessToken = await refreshTokenInterceptor().catch((err) => {
		console.error('refreshTokenInterceptor_err ', err);
		return Promise.reject({refreshTokenError: err})
	} )
	// if (refreshToken) return refreshToken
	// const accessToken = localStorage.getItem(accessTokenKey);
	// console.log('axios_accessToken ', accessToken)
	if (accessToken) {
		request.headers.Authorization = `Bearer ${accessToken}`;
	}
	return request;
}, 
(error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use(null, error => {
	// if (error.response.status === 401) window.location.reload();

	const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

	console.log('axios.interceptors.response ', error)
	const refreshTokenError = error?.refreshTokenError;
	console.log('axios.interceptors.response_refreshTokenError ', refreshTokenError)

	if (!expectedError && !refreshTokenError) {
		sentry_log(error);
		alert("An unexpected error occurrred.");
	}

	return Promise.reject(error);
});

export default {
	get: axios.get,
	post: axios.post,
	put: axios.put,
	delete: axios.delete,
};
