import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchFeedData, fetchFeedNext } from "../../redux";

// Import Components
import FeedItem from "../feedItem/feedItem";

// Import Images
import feedIcon from "../../img/assets/feed_icon.svg";
import hideFeedArrow from "../../img/icons/blue/right-arrow.svg";
import emptyFeed from "../../img/static/feed_empty.svg";
import offerLoadingDots from "../../img/spinners/offer_loading.gif";
import FeedBarLoadingBlock from "../../img/spinners/blocks/feed bar.svg";

// Import CSS
import "./feedBar.css";

class FeedBar extends Component {
	state = {
		showFeed: false,
	};

	// When Mount
	componentDidMount() {
		//Populate Feed List
		this.props.fetchFeedData();
	}

	// Helper Methods
	toggleFeed = () => {
		this.setState({ showFeed: !this.state.showFeed });
	};

	handleScroll = (e) => {
		const hitBottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;

		if (hitBottom && !this.props.feedData.paginate.loading) {
			if (this.props.feedData.feed.next !== null) {
				this.props.fetchFeedNext();
			}
		}
	};

	render() {
		const { loading, feed, paginate } = this.props.feedData;
		const feedVisible = this.state.showFeed ? " feed-visible" : "";

		// Render
		return (
			<div className="d-none d-sm-block">
				<div className="feed-show-div" onClick={this.toggleFeed}>
					<img src={feedIcon} alt="Show Feed" className="feed-icon" />
				</div>

				<div className={`feed${feedVisible}`} onScroll={this.handleScroll}>
					{/* Feed Header */}
					<div className="font-weight-bold d-flex border-bottom">
						<div className="hide-feedbar-arrow align-content-center p-4 pointer" onClick={this.toggleFeed}>
							<img src={hideFeedArrow} alt=">" />
						</div>

						<div className="d-flex justify-content-center align-items-center flex-grow-1 py-4">
							<img src={feedIcon} alt="Feed" />
							<span className="ml-2">Feed</span>
						</div>
					</div>

					{/* Loading */}
					{loading && (
						<div className="d-flex flex-column">
							<span className="my-4">
								<img src={FeedBarLoadingBlock} alt="Loading..." />
							</span>
						</div>
					)}

					{/* Zero state */}
					{!loading && feed.results.length === 0 && (
						<div className="d-flex flex-column">
							<span className="my-4">Nothing here! Check back later for updates.</span>
							<img src={emptyFeed} alt="Empty feed" />
						</div>
					)}

					{/* Feed Cards */}
					{!loading && feed.results.length !== 0 && (
						<div className="feed-items-container">
							{feed.results.map((item) => (
								<FeedItem key={item.id} item={item} />
							))}

							{/* Loading Spinner */}
							{paginate.loading && (
								<div className="d-flex justify-content-center w-100">
									<img className="feedbar-loading-dots" src={offerLoadingDots} alt="Loading" />
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		feedData: state.feed,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		fetchFeedData: () => dispatch(fetchFeedData()),
		fetchFeedNext: () => dispatch(fetchFeedNext()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedBar);
